import {Box} from "@mui/material";
import {useHistory} from 'react-router';
import LeftMenu from "../components/LeftMenu";
import {makeStyles} from "@mui/styles";
import React, {useEffect, useState} from "react";
import {BoardType, CommonCodeValue} from "../common/types";
import CategorySettings from "./category/CategorySettings";
import CodeSettings from "./code/CodeSettings";
import {Route, useLocation} from 'react-router-dom';
import GalleryBoard from "./board/GalleryBoard";
import BulletinBoard from "./board/BulletinBoard";
import api from "../common/api";

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100vh',
        overflow: 'overlay',
        backgroundColor: theme.palette.secondary.main,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        '& > *': {
            flex: 1
        }
    },
    main: {
        height: '100%',
        width: '100%',
        minHeight: 880,
        minWidth: 1300,
        overflowX: 'hidden'
    }
}));

export default function LayoutPage(props) {

    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const [update, setUpdate] = useState(false);
    const [categories, setCategories] = useState(null);

    const [index, setIndex] = useState(0);
    const [openFirst, setOpenFirst] = useState(false);

    const openPage = (category, idx, type) => {
        if (type === "board" && BoardType.getName(category.type)=== "게시판") {
            history.push(`/bulletin/${category.name}`, {menuIdx: idx, type: type});
        } else if (type === "category") {
            history.push('/category/settings', {menuIdx: idx, type: type});
        } else if (type === "code") {
            history.push('/code/settings', {menuIdx: idx, type: type});
        } else {
            history.push(`/gallery/${category.name}`, {menuIdx: idx, type: type});
        }
    }

    const fetchData = () => {
        api.getCategoryList()
            .then((data) => {
                setCategories(data.data);
            })
            .catch((err) => {
                console.log(CommonCodeValue.getErrMsg(err.rtCode))
            })
    }

    const updateMenu = (action) => {
        fetchData();
        if (action === "added") {
            history.push('/category/settings', {menuIdx: index + 1, type: "category"});
        } else if (action === "deleted") {
            history.push('/category/settings', {menuIdx: index - 1, type: "category"});
        }
        setUpdate(!update)
    }

    useEffect(() => {
        fetchData()
        if (location.state?.menuIdx !== undefined) {
            setIndex(location.state.menuIdx);
        }
    }, [location.state?.menuIdx]);

    useEffect(() => {
        if (categories!==null && categories.length > 0 && location.state?.menuIdx === undefined) {
            BoardType.getName(categories[0].type) === "게시판" ?
                openPage(categories[0], 0, "board") :
                openPage(categories[0], 0, "gallery")
        } else if (categories!==null && categories.length === 0 && location.state?.menuIdx === undefined) {
            openPage(null, 0, "category")
        }
    }, [openFirst]);

    if (categories!==null && !openFirst) {
        setOpenFirst(true)
    }

    return (
        <Box className={classes.container}>
            <LeftMenu openPage={openPage} categories={categories} update={update} {...props} index={index}/>
            <main className={classes.main}>
                {categories!==null && categories.length > 0 &&
                    categories.filter((category) => BoardType.getName(category.type) === "게시판").map((category) => (
                        <Route path={`/bulletin/${category.name}`} key={category.seq}
                               render={props => (<BulletinBoard {...props} category={category}/>)}/>
                        ))}
                {categories!==null && categories.length > 0 &&
                    categories.filter((category) => BoardType.getName(category.type) === "갤러리").map((category) => (
                        <Route path={`/gallery/${category.name}`} key={category.seq}
                               render={props => (<GalleryBoard {...props} category={category}/>)}/>
                    ))}
                <Route path={'/category/settings'} key={"category-settings"} render={props => (<CategorySettings {...props} updateMenu={updateMenu}/>)}/>
                <Route path={'/code/settings'} key={"code-settings"} render={props => (<CodeSettings {...props}/>)}/>
            </main>
        </Box>
    )
}
import React, {useState} from "react";
import {Box, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    otpField: {
        margin: '20px 0',
        '& input': {
            height: 50,
            fontSize: 25,
            padding: '0 18px',
            borderRadius: 5,
            border: '1px solid #DBDADA',
            '&:hover': {
                border: '1px solid #3C39CB',
            },
            '&:focus-visible': {
                border: '1px solid #3C39CB',
                outline: 'none !important'
            }
        }
    }
}));

export default function OtpPage(props) {
    const {gccs, onLogin, onError, onClose, onOtpVerifySuccess} = props;
    const classes = useStyles();
    const [countDown, setCountDown] = useState(null);

     const verifyOtp = () => {
         const otpInput = document.getElementById("otpInput");
         const otpCode = otpInput.value;
         if (otpCode.length != 6) {
             alert("Please enter a 6-digit otp code.");
             return;
         }
         onClose();

         gccs.requestOtpCallback(otpInput, (token) => {
             onLogin(token);
         }, (errorCode, errorMsg) => {
             onError(errorMsg);
         }, (data) => onOtpVerifySuccess(data));
     }

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" className={classes.otpField}>
            <Box display="flex" justifyContent="center">
                <input id="otpInput" type="number"
                       onKeyPress={event => {
                            if (event.key === 'Enter') {
                                verifyOtp();
                            }
                }}/>
            </Box>
            {countDown !== null &&
                <Typography variant='body1'>남은 시간 : {countDown}초</Typography>
            }
        </Box>
    )
}
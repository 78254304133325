import {TextField, Typography} from "@mui/material";
import React from "react";

export default function TextInput({
                                    form,
                                    name,
                                    label,
                                    errors,
                                    onChange,
                                    className,
                                    maxLength,
                                    multiline = false
                                  }) {

    const field = form.$(name);
    const required = field.rules?.includes('required');
    const error = errors?.[name];

    return (
        <React.Fragment>
            <Typography variant='h4'>
                <span hidden={!required}>*</span>
                &nbsp;{label}
            </Typography>
            <TextField
                defaultValue={field.value}
                className={className}
                onChange={onChange}
                multiline={multiline}
                inputProps={{ maxLength : maxLength }}
                error={errors === null ? false : error !== null}
                placeholder={errors === null ? '' : required && error !== null ? "필수 항목값을 입력해주세요" : ''}
                helperText={field.value.trim() !== '' && error?.includes("valid") ? `잘못된 ${label} 형식입니다` : null}
            />
        </React.Fragment>
    )
}
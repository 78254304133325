import React, {useEffect, useState} from 'react';
import {Route, Switch, useLocation, useRouteMatch} from "react-router-dom";
import {useHistory} from 'react-router';
import ReorderPage from "../../components/ReorderPage";
import BoardListPage from "./BoardListPage";
import BoardDetailPage from "./BoardDetailPage";
import BoardEditPage from "./BoardEditPage";
import BoardCreatePage from "./BoardCreatePage";

export default function BulletinBoard(props) {

    const { category } = props;
    const history = useHistory();
    const { state } = useLocation();
    let { path, url } = useRouteMatch();
    const [menuId, setMenuId] = useState(state.menuIdx);

    function onPageChange(pageType, board) {
        if (pageType==="list") {
            history.push(`${url}`, {menuIdx: menuId, category: category})
        } else if (pageType==="detail") {
            history.push(`${url}/detail/${board.seq}`, {menuIdx: menuId, board: board, category: category})
        } else if (pageType==="create") {
            history.push(`${url}/create`, {menuIdx: menuId, category: category})
        } else if (pageType==="edit") {
            history.push(`${url}/edit/${board.seq}`, {menuIdx: menuId, board: board, category: category})
        } else {
            history.push(`${url}/reorder`, {menuIdx: menuId, category: category})
        }
    }

    useEffect(() => {
        setMenuId(state.menuIdx);
    }, []);

    return (
        <React.Fragment>
            <Switch>
                <Route exact path={path}
                       render={props => (<BoardListPage {...props} category={category} openPage={onPageChange} />)}/>
                <Route path={`${path}/detail/:boardSeq`}
                       render={props => (<BoardDetailPage {...props} openPage={onPageChange}/>)}/>
                <Route path={`${path}/edit/:boardSeq`}
                       render={props => (<BoardEditPage {...props} openPage={onPageChange} pageTitle={'게시물 수정'}/>)}/>
                <Route exact path={`${path}/create`}
                       render={props => (<BoardCreatePage {...props} openPage={onPageChange} pageTitle={'게시물 등록'}/>)}/>
                <Route exact path={`${path}/reorder`}
                       render={props => (<ReorderPage target={"board"}
                                                      headerTitle={category.name}
                                                      headerDescription={"게시된 게시물의 순서를 변경할 수 있는 페이지입니다."}
                                                      searchPlaceholder={"게시물 내 검색"}
                                                      {...props}/>)}>
                </Route>
            </Switch>
        </React.Fragment>
    );
}
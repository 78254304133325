import {makeStyles} from "@mui/styles";
import React, {useEffect, useState} from "react";
import api from "../common/api";
import Header from "../components/Header";
import {Alert, Box, Button, Snackbar, Typography} from "@mui/material";
import {dataFormat} from "../utils/FormatUtils";
import {ReactComponent as DragIcon} from "../images/icons/drag_icon.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {useHistory, useLocation} from "react-router-dom";
import SearchBar from "./SearchBar";
import ConfirmDialog from "./ConfirmDialog";
import {CommonCodeValue} from "../common/types";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: 700,
        minWidth: 1160,
        margin: '0 130px',
        '& > :first-child': {
            marginBottom: 30
        }
    },
    tableHead: {
        height: 50,
        backgroundColor: '#fff',
        borderRadius: 5,
        '& > *': {
            color: theme.palette.text.dark,
            fontWeight: 'bold',
            fontSize: '0.875rem',
            flex: 1
        },
        '& > :first-child': {
            paddingLeft: 78
        },
        '& > :last-child': {
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: 80
        }
    },
    listBody: {
        marginTop: 10,
        '& > :not(:last-child)': {
            marginBottom: 10
        }
    },
    emptyList: {
        height: 60,
        backgroundColor: '#fff',
        borderRadius: 5,
        justifyContent: 'center'
    },
    listItem: {
        height: 60,
        backgroundColor: '#fff',
        borderRadius: 5,
        marginBottom: 10,
        '& > :first-child': {
            flex: 1,
            maxWidth: 78,
            padding: '20px 30px'
        },
        '& > :nth-child(2)': {
            flex: 10,
            color: theme.palette.text.secondary,
            fontWeight: 700,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            wordBreak: 'break-word'
        },
        '& > :last-child': {
            flex: 3,
            display:'flex',
            color: theme.palette.text.secondary,
            justifyContent: 'flex-end',
            paddingRight: 47
        },
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
            border: '1px solid #154782'
        }
    },
    codeName: {
        '& > :first-child': {
            color: '#9FA5B6',
            fontWeight: 700,
            marginBottom: 5
        },
        '& > :last-child': {
            color: theme.palette.primary.main,
            fontWeight: 700
        },
    },
    pageBottom: {
        marginTop: 22,
        alignItems: 'center',
        '& > *': {
            flex: 1
        },
        '& > :first-child > *': {
            fontSize: '0.875rem',
            color: '#9398A7'
        },
        '& > :first-child > :nth-child(2)': {
            color: theme.palette.primary.main,
            fontWeight: 'bold'
        },
        '& > :first-child > :last-child': {
            fontWeight: 'bold'
        }
    },
    buttons: {
        '& > button': {
            boxShadow: 'none',
            height: 50,
            width: 190,
            fontWeight: 'bold'
        },
        '& > :first-child': {
            border: '1px solid #9FA5B6',
            marginRight: 10,
            backgroundColor: '#fff',
            color: theme.palette.primary.main
        }
    }
}));

export default function ReorderPage(props) {

    const classes = useStyles();
    const history = useHistory();
    const {state} = useLocation();
    const { target, searchPlaceholder, headerTitle, headerDescription, updateMenuList } = props;
    const groupSeq = props.location.state.groupSeq;

    const [itemList, setItemList] = useState([]);
    const [totalPosts, setTotalPosts] = useState(0)
    const [keyword, setKeyword] = useState('');
    const [openSaveConfirm, setOpenSaveConfirm] = useState(false);

    // 공통코드 순서 변경
    const fetchCodeList = () => {
        api.getSortedCodeList(groupSeq)
            .then((data) => {
                setItemList(data.data.data);
                setTotalPosts(data.data.data.length)
            })
            .catch((err) => {
                console.log(CommonCodeValue.getErrMsg(err.rtCode))
            })
    }
    const updateCodeList = () => {
        api.updateSortedCodeList(groupSeq, itemList)
            .then((data) => {
                setItemList(data.data.data);
                setTotalPosts(data.data.data.length)
            })
            .catch((err) => {
                console.log(CommonCodeValue.getErrMsg(err.rtCode))
            })
    }

    // 게시판 순서 변경
    const fetchCategoryList = () => {
        api.getCategoryList()
            .then((data) => {
                setItemList(data.data);
                setTotalPosts(data.data.length);
            })
            .catch((err) => {
                console.log(CommonCodeValue.getErrMsg(err.rtCode))
            })
    }
    const updateCategoryList = () => {
        api.updateSortedCategoryList(itemList)
            .then((data) => {
                setItemList(data.data.data);
                setTotalPosts(data.data.data.length);
                updateMenuList();
            })
            .catch((err) => {
                console.log(CommonCodeValue.getErrMsg(err.rtCode))
            })
    }

    // 게시글 순서 변경
    const fetchBoardList = (seq) => {
        api.getSortedBoardList(seq)
            .then((data) => {
                setItemList(data.data);
                setTotalPosts(data.data.length);
            })
            .catch((err) => {
                console.log(CommonCodeValue.getErrMsg(err.rtCode))
            })
    }
    const updateBoardList = (seq) => {
        let sortList = { sortList: [] };
        itemList.map((item, idx) => sortList.sortList[idx] = {seq: item.seq, sort: item.sort})

        api.updateSortedBoardList(seq, sortList)
            .then((data) => {
                setItemList(data.data.data);
                setTotalPosts(data.data.data.length);
            })
            .catch((err) => {
                console.log(CommonCodeValue.getErrMsg(err.rtCode))
            })
    }

    const handleReorder = (result) => {

        if (!result.destination) return;
        const source = result.source.index;
        const destination = result.destination.index;

        const items = Array.from(itemList);
        const [reorderedItem] = items.splice(source, 1);
        items.splice(destination, 0, reorderedItem);
        items.map((item, idx) => {
            item.sort = idx + 1;
        })
        setItemList(items);
    };

    const onSave = () => {
        if (target === "commonCode") {
            updateCodeList();
        } else if (target === "category") {
            updateCategoryList();
        } else if (target === "board") {
            updateBoardList(state.category.seq)
        }
        setOpenSaveConfirm(false);
    }

    const getIconStyle = (isDragging) => ({
        cursor: isDragging ? "pointer" : "move"
    });

    useEffect(() => {
        if (target === "commonCode") {
            fetchCodeList();
        } else if (target === "category") {
            fetchCategoryList();
        } else if (target === "board") {
            fetchBoardList(state.category.seq)
        }
    }, []);

    return (
        <React.Fragment>
            <Header name={headerTitle} navMain={headerTitle} navSecond={"순서 변경"} description={headerDescription}/>
            <Box className={classes.root}>

                <Box display='flex'>
                    <SearchBar placeholder={searchPlaceholder} onSearch={(value) => setKeyword(value)}/>
                    <Box display='flex' flex={1} justifyContent='flex-end' className={classes.buttons}>
                        <Button variant="contained" onClick={() => history.goBack()}>목록보기</Button>
                        <Button variant="contained" disabled={totalPosts < 1} onClick={() => setOpenSaveConfirm(true)}>저장</Button>
                    </Box>
                </Box>

                <Box display='flex' alignItems='center' className={classes.tableHead}>
                    <Typography>제목</Typography>
                    <Typography>등록일</Typography>
                </Box>
                <Box className={classes.listBody}>
                    {itemList.length > 0 &&
                        <DragDropContext onDragEnd={handleReorder}>
                            <Droppable droppableId="droppable">
                                {(provided) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {itemList.map((item, index) => (
                                            <Draggable key={item.seq} draggableId={item.seq.toString()} index={index}>
                                                {(provided, snapshot) => (
                                                    <div ref={provided.innerRef} {...provided.draggableProps} style={provided.draggableProps.style}>
                                                        <Box display='flex' alignItems='center'
                                                             className={classes.listItem}>
                                                            <Box {...provided.dragHandleProps}
                                                                 style={getIconStyle(snapshot.isDragging)}>
                                                                <DragIcon/>
                                                            </Box>
                                                            <Typography>{item.name ? highlightText(item.name, keyword) : highlightText(item.boardList[0].title, keyword)}</Typography>
                                                            <Typography>{dataFormat(item.regDt, 'date')}</Typography>
                                                        </Box>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    }
                </Box>

                {totalPosts === 0 &&
                    <Box display='flex' flexDirection='row' alignItems='center' className={classes.emptyList}>
                        <Typography>조회할 게시물이 없습니다</Typography>
                    </Box>
                }
                
                <Box display='flex' justifyContent='flex-end' className={classes.pageBottom}>
                    <Box display='flex'>
                        <Typography>Total:&nbsp;</Typography>
                        <Typography>{totalPosts}</Typography>
                        <Typography>&nbsp;posts</Typography>
                    </Box>
                    <Box display='flex' justifyContent='flex-end' className={classes.buttons}>
                        <Button variant="contained" onClick={() => history.goBack()}>목록보기</Button>
                        <Button variant="contained" disabled={totalPosts < 1} onClick={() => setOpenSaveConfirm(true)}>저장</Button>
                    </Box>
                </Box>
                <ConfirmDialog open={openSaveConfirm}
                               title={"순서 변경 저장"}
                               content={"변경된 순서를 저장 하시겠습니까?"}
                               onClose={() => setOpenSaveConfirm(false)}
                               onOk={onSave}/>
            </Box>
        </React.Fragment>
    )
}

function highlightText(text, query) {
    if (query !== '' && text.toLowerCase().includes(query.toLowerCase())) {
        const parts = text.split(new RegExp(`(${query})`, 'gi'));
        return (
            <React.Fragment>
                {parts.map((part, index) =>
                    part.toLowerCase() === query.toLowerCase() ? <mark key={index}>{part}</mark> : part
                )}
            </React.Fragment>
        );
    }
    return text;
}
import moment from "moment";

export function dataFormat(data, dataType) {
    if (data === undefined || data === null) {
        return null;
    }
    if (dataType === 'date') {
        let dateFormat = 'YYYY-MM-DD';
        return moment(data, 'YYYY-MM-DD hh:mm:ss').format(dateFormat);
    }
    if (dataType === 'datetime') {
        let dateFormat = 'YYYY-MM-DD';
        return moment(data, 'YYYY-MM-DD hh:mm:ss Z').format(dateFormat);
    }
    return data;
}
import React, {useEffect, useRef, useState} from 'react';
import Header from "../../components/Header";
import {makeStyles} from "@mui/styles";
import {Box, Tabs, Tab, Typography, Button, IconButton, CircularProgress} from "@mui/material";
import PropTypes from 'prop-types';
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import {
    arrayMove,
    rectSortingStrategy,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable
} from "@dnd-kit/sortable";
import {CSS} from '@dnd-kit/utilities';
import {
    closestCenter,
    DndContext,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import ConfirmDialog from "../../components/ConfirmDialog";
import api from "../../common/api";
import WarningDialog from "../../components/WarningDialog";
import {CommonCodeValue, Language} from "../../common/types";
import MoveCursor from '../../images/icons/move_cursor.png';
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 1040,
        margin: '0 130px 50px 130px',
        '& .MuiTab-root': {
            width: 135,
            height: 52,
            backgroundColor: theme.palette.secondary.main,
            border: '1px solid #C8CACE',
            '&:after': {
                backgroundColor: theme.palette.secondary.main,
                borderBottom: '1px solid #C8CACE'
            }
        },
        '& .Mui-selected': {
            backgroundColor: '#fff',
            borderBottom: '1px solid #ffffff',
            zIndex: '1 !important',
            '&:after': {
                backgroundColor: '#fff !important',
                borderBottom: '1px solid #fff !important'
            }
        },
        '& .MuiTabs-indicator': {
            display: 'none'
        },
        '& > :first-child': {
            position: 'relative',
            zIndex: 10,
        },
        '& > :nth-child(2) , > :nth-child(3)': {
            position: 'relative',
            zIndex: 0,
            top: '-1px'
        }
    },
    tabs: {
        '& .MuiTabs-flexContainer': {
            '& > button': {
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
                zIndex: 0,
                overflow: 'visible',
                marginRight: 32,
                padding: '12px 10px 12px 30px',
                '&:after': {
                    position: 'absolute',
                    content: '""',
                    width: 44,
                    height: 52,
                    right: '-23px',
                    transform: 'skewX(20deg)',
                    borderRight: '1px solid #C8CACE',
                    borderTop: '1px solid #C8CACE',
                    borderTopRightRadius:  5
                },
            },
            '& > :nth-child(2)': {
                marginLeft: '-7px'
            }
        }
    },
    tabLabel: {
        display: 'flex',
        alignItems: 'center',
        '& > p': {
            color: theme.palette.text.gray,
            fontSize: 14,
            fontWeight: 800,
            fontFamily: ['sans-serif', 'Source Sans Pro', 'Nanum Gothic'],
            marginRight: 7
        },
        '& > span': {
            backgroundColor: '#9FA5B6',
            borderRadius: 15,
            width: 30,
            height: 21,
            fontSize: 11,
            fontWeight: 'bold',
            color: '#fff',
            padding: 4,
            zIndex: 100
        }
    },
    tabLabelOpen: {
        display: 'flex',
        alignItems: 'center',
        '& > p': {
            color: '#303030',
            fontSize: 14,
            fontWeight: 800,
            fontFamily: ['sans-serif', 'Source Sans Pro', 'Nanum Gothic'],
            marginRight: 7
        },
        '& > span': {
            backgroundColor: '#F8B849',
            borderRadius: 15,
            width: 30,
            height: 21,
            fontSize: 11,
            fontWeight: 'bold',
            color: '#fff',
            padding: 4,
            zIndex: 100
        }
    },
    panel: {
        backgroundColor: '#FFF',
        border: '1px solid #C8CACE',
        minHeight: 570,
        padding: '50px',
        borderRadius: 5,
        borderTopLeftRadius: 0,
        marginBottom: 30
    },
    contents: {
        display: "flex",
        flexWrap: 'wrap',
        columnGap: 45,
        rowGap: 45,
        overflowY: 'auto',
        maxHeight: 530,
        '& > button': {
            width: 260,
            height: 240,
            backgroundColor: '#fff',
            color: theme.palette.primary.main,
            border: '1px solid #E7E8EB',
            fontWeight: 'bold',
            borderRadius: 0,
            flexDirection: 'column',
            '& > .MuiSvgIcon-root': {
                backgroundColor: '#FFF',
                border: '1.5px solid #154782',
                borderRadius: '50%',
                width: 35,
                height: 35,
                padding: 8,
                marginBottom: 10
            }
        }
    },
    loadImgBox: {
        border: '1px solid #E7E8EB',
        width: 260,
        height: 240
    },
    previewBox: {
        border: '1px solid #E7E8EB',
        '& figure': {
            width: 260,
            height: 240,
            margin: 0,
            display: 'flex',
            alignItems: 'flex-start',
            overflow: 'hidden',
            position: 'relative',
            '& img': {
                backgroundColor: 'white',
                opacity: 1,
                objectFit: 'contain',
                width: 260,
                height: 240
            },
            '& > p': {
                display: 'none',
                maxWidth: 200,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                marginLeft: 10
            },
            '& > .MuiIconButton-root': {
                display: 'none',
                position: 'absolute',
                color: '#fff',
                width: 30,
                height: 30, marginTop: 5,
                padding: 0,
                '& > svg': {
                    width: 18,
                    height: 18
                }
            },
            '&:hover': {
                backgroundColor: '#000',
                opacity: 0.9,
                '& img': {
                    opacity: 0.5
                },
                '& > p': {
                    display: 'block',
                    position: 'absolute',
                    color: '#fff'
                },
                '& > .MuiIconButton-root': {
                    display: 'block',
                    marginLeft: 220
                },
            }
        }
    },
    buttons: {
        '& > button': {
            boxShadow: 'none',
            height: 50,
            width: 190,
            fontWeight: 'bold',
        },
        '& > :not(:last-child)': {
            marginRight: 10,
            border: '1px solid #9FA5B6',
            backgroundColor: '#fff',
            color: theme.palette.primary.main
        }
    }
}));

function TabPanel(props) {

    const { children, value, index, categorySeq, boardSeq, post, editable, closeEdit, setTotal, updated, ...other } = props;
    const classes = useStyles();
    const [images, setImages] = useState([]);
    const [addImage, setAddImage] = useState([]);
    const [items, setItems] = useState([])
    const [deleteImgSeq, setDeleteImgSeq] = useState(null);
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [loading, setLoading] = useState(false);
    const originalSort = useRef([]);

    const fetchImages = () => {
        api.getBoardByLanguage(categorySeq, boardSeq, post.seq)
            .then((data) => {
                setImages(data.data.images);
                originalSort.current = Array.from({length: data.data.images.length}, (elm, index) => {
                    elm = {id : (index+1).toString()};
                    return elm;
                });
                setTotal(data.data.images.length);
                setLoading(false);
            })
            .catch((err) => {
                console.log(CommonCodeValue.getErrMsg(err.rtCode));
                setLoading(false);
            })
    }

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates }),
    );

    function openFileExplorer() {
        document.getElementById(`upload-image-${index}`).click();
    }

    function selectFile(file) {
        if (file && file.size / 1024 / 1024 > 10) {
            alert('허용된 업로드 용량(10Mb 이하)을 초과했습니다')
        } else if (file && file['type'].split('/')[1].match(/^(jpeg|png|jpg|gif|bmp)$/)) {
            addImage.push(file);
            saveImage();
        } else if (file && file['type'].split('/')[0] === 'image') {
            alert('png, jpg, jpeg, gif, bmp 이미지 파일만 첨부 가능합니다')
        }
    }

    const saveImage = () => {
        let uptPost = {
            seq: post.seq,
            language: post.language,
            title: post.title,
        }

        api.updateBoardByLanguage(categorySeq, boardSeq, post.seq, uptPost, addImage)
            .then((res) => {
                setAddImage([]);
                setImages(res.data.data.images);
                originalSort.current = Array.from({length: res.data.data.images.length}, (elm, index) => {
                    elm = {id : (index+1).toString()};
                    return elm;
                });
                setLoading(false);
            })
            .catch((err) => {
                console.log(CommonCodeValue.getErrMsg(err.rtCode))
                setLoading(false);
            })
    }

    const handleImageDelete = () => {
        let uptPost = {
            seq: post.seq,
            language: post.language,
            title: post.title,
            removeImageSeqList : [deleteImgSeq]
        }

        api.updateBoardByLanguage(categorySeq, boardSeq, post.seq, uptPost, null)
            .then((res) => {
                setImages(res.data.data.images)
                originalSort.current = Array.from({length: res.data.data.images.length}, (elm, index) => {
                    elm = {id : (index+1).toString()};
                    return elm;
                });
                setOpenDeleteConfirm(false);
            })
            .catch((err) => {
                console.log(CommonCodeValue.getErrMsg(err.rtCode))
            })
    }

    const handleReorderSave = () => {
        let sortList = { sortList: [] };
        items.map((item, idx) => {
            sortList.sortList[idx] = {seq: images[item.id-1].seq, sort: idx+1}
        })
        if (sortList.sortList.length > 0) {
            api.updateSortedImageList(categorySeq, boardSeq, post.seq, sortList)
                .then((res) => {
                    setImages(res.data.data.images);
                    originalSort.current = Array.from({length: res.data.data.images.length}, (elm, index) => {
                        elm = {id : (index+1).toString()};
                        return elm;
                    });
                    closeEdit();
                })
                .catch((err) => {
                    console.log(CommonCodeValue.getErrMsg(err.rtCode))
                })
        } else {
            closeEdit()
        }
    };

    function handleDragEnd(event) {
        const {active, over} = event;
        if (active.id !== over.id) {
            setItems((items) => {
                const oldIndex = items.findIndex(({id}) => id === active.id);
                const newIndex = items.findIndex(({id}) => id === over.id);
                return arrayMove(items, oldIndex, newIndex);
            });
        }
    }

    useEffect(() => {
        fetchImages()
    }, []);

    useEffect(() => {
        if (images !== []) {
            setItems(
                Array.from({length: images.length}, (elm, index) => {
                    elm = {id : (index+1).toString()};
                    return elm;
                }));
        }
    }, [editable]);

    useEffect(() => {
        if(originalSort.current !== [] && items !== []) {
            if (_.isEqual(items, originalSort.current)) {
                updated(false)
            } else {
                updated(true)
            }
        }
    }, [items]);

    return (
        <div role="tabpanel" hidden={value !== index} {...other}>
            <Box sx={{ p: 3 }} className={classes.panel}>
                {!editable ?
                    <Box className={classes.contents} color='dark' onChange={() => {if (addImage.length > 0) setLoading(true)}}>
                        {images.length > 0 &&
                            images.map((image, idx) => (
                                <Box key={idx} className={classes.previewBox}>
                                    <figure>
                                        <img className="preview"
                                             src={image.encodingSrc} alt={image.originalName}/>
                                        <p>{image.originalName}</p>
                                        <IconButton onClick={() => {
                                            setDeleteImgSeq(image.seq);
                                            setOpenDeleteConfirm(true);
                                        }}>
                                            <ClearIcon/>
                                        </IconButton>
                                    </figure>
                                </Box>
                            ))
                        }
                        {loading &&
                            <Box key={'loading-progress'} className={classes.loadImgBox} display='flex' justifyContent='center' alignItems='center'>
                                <CircularProgress/>
                            </Box>
                        }
                        <input type="file" id={`upload-image-${index}`} hidden accept="image/*"
                               onChange={(e) => selectFile(e.target.files[0])}
                               onClick={(event) => event.target.value = null}
                        />
                        <Button onClick={() => openFileExplorer()}>
                            <AddIcon/>
                            이미지 추가
                        </Button>
                    </Box>
                    :
                    <DndContext sensors={sensors}
                                collisionDetection={closestCenter}
                                onDragEnd={handleDragEnd}>
                        <SortableContext items={items.map(({id}) => id)}
                                         strategy={rectSortingStrategy}>
                            <Box className={classes.contents}>
                                {items.map(({id}) => (
                                    <SortableItem key={id} id={id} images={images}/>
                                ))}
                            </Box>
                        </SortableContext>
                    </DndContext>
                }
            </Box>
            <button hidden id={`save-list-${index}`} onClick={handleReorderSave}>save</button>
            <WarningDialog open={openDeleteConfirm}
                           onClose={() => setOpenDeleteConfirm(false)}
                           onOk={handleImageDelete}
                           title={"이미지 삭제"}
                           content={"이미지 파일을 삭제하시겠습니까?"}
            />
        </div>
    );
}

function SortableItem(props) {

    const classes = useStyles();
    const {images} = props;

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging
    } = useSortable({id: props.id});

    const style = {
        transform: CSS.Transform.toString(transform),
        zIndex: isDragging ? 100 : null,
        transition
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <Box className={classes.previewBox} sx={{'&:hover': { cursor: `url(${MoveCursor}), auto`}}} >
                <figure>
                    <img className="preview"
                         src={images[props.id-1]?.encodingSrc}
                         alt={images[props.id-1]?.originalName}/>
                    <p>{images[props.id-1]?.originalName}</p>
                </figure>
            </Box>
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function GalleryPage(props) {

    const classes = useStyles();
    const {category} = props;

    const [board, setBoard] = useState(null);
    const [koreanPost, setKoreanPost] = useState(null);
    const [englishPost, setEnglishPost] = useState(null);

    const [koreanPostCount, setKoreanPostCount] = useState(0);
    const [englishPostCount, setEnglishPostCount] = useState(0);

    const [editable, setEditable] = useState(false);    // 이미지 순서 변경
    const [value, setValue] = useState(0);

    const [openSaveConfirm, setOpenSaveConfirm] = useState(false);
    const [openCancelConfirm, setOpenCancelConfirm] = useState(false);
    const [openNoChange, setOpenNoChange] = useState(false);
    const [isChanged, setIsChanged] = useState([false, false]);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const getBoardGroupData = () => {
        api.getBoardGroupList(category.seq, null,null,null, null)
            .then((data) => {
                setBoard(data.data[0])
                setKoreanPost(data.data[0].boardList.find(board => board.language === Language.Korean))
                setEnglishPost(data.data[0].boardList.find(board => board.language === Language.English))
            })
            .catch((err)=> {
                console.log(CommonCodeValue.getErrMsg(err.rtCode))
            })
    }

    const onCancelEdit = () => {
        getBoardGroupData();
        setIsChanged([false, false]);
        setEditable(false)
        setOpenCancelConfirm(false)
    }

    useEffect(() => {
        getBoardGroupData();
    }, []);

    return (
        <React.Fragment>
            <Header name={category?.name} navMain={category?.name} navSecond={" 게시물 상세"} description={category?.description} {...props}/>
            {board!==null && koreanPost!==null && englishPost!==null &&
                <Box className={classes.root}>
                    <Box display='flex' alignItems='flex-end' justifyContent='space-between'>
                        <Tabs value={value} onChange={handleTabChange} className={classes.tabs}>
                            <Tab label={
                                <Box className={value === 0 ? classes.tabLabelOpen : classes.tabLabel}>
                                    <Typography>한국어</Typography>
                                    <span>{koreanPostCount}</span>
                                </Box>
                            }
                                 disableRipple {...a11yProps(0)} />
                            <Tab label={
                                <Box className={value === 1 ? classes.tabLabelOpen : classes.tabLabel}>
                                    <Typography>영어</Typography>
                                    <span>{englishPostCount}</span>
                                </Box>
                            } disableRipple {...a11yProps(1)} />
                        </Tabs>
                    </Box>

                    <TabPanel value={value} index={0} editable={editable}
                              categorySeq={category.seq}
                              boardSeq={board.seq}
                              post={koreanPost}
                              setTotal={(num) => setKoreanPostCount(num)}
                              closeEdit={() => setEditable(false)}
                              updated={(value) => isChanged[0] = value}/>
                    <TabPanel value={value} index={1} editable={editable}
                              categorySeq={category.seq}
                              boardSeq={board.seq}
                              post={englishPost}
                              setTotal={(num) => setEnglishPostCount(num)}
                              closeEdit={() => setEditable(false)}
                              updated={(value) => isChanged[1] = value}/>

                    <Box display='flex' justifyContent='flex-end' className={classes.buttons}>
                        {!editable &&
                            <Button variant='contained'
                                    onClick={() => setEditable(true)}>순서 변경</Button>}
                        {editable &&
                            <React.Fragment>
                                <Button variant='contained'
                                        onClick={() => (isChanged[0] || isChanged[1]) ? setOpenCancelConfirm(true) : onCancelEdit()}>취소</Button>
                                <Button variant='contained'
                                        onClick={() => (isChanged[0] || isChanged[1]) ? setOpenSaveConfirm(true) : setOpenNoChange(true)}>저장</Button>
                            </React.Fragment>
                        }
                    </Box>
                </Box>
            }
            <WarningDialog open={openCancelConfirm}
                           onClose={() => setOpenCancelConfirm(false)}
                           onOk={onCancelEdit}
                           title={'수정 취소'}
                           content={'수정한 이미지 순서는 초기화됩니다.\n 취소하시겠습니까?'}
            />
            <WarningDialog open={openNoChange}
                           onClose={() => setOpenNoChange(false)}
                           onOk={() => setOpenNoChange(false)}
                           title={'순서 변경'}
                           content={'변경된 내용이 없습니다'}
            />
            <ConfirmDialog open={openSaveConfirm}
                           onClose={() => setOpenSaveConfirm(false)}
                           onOk={() => {
                               document.getElementById('save-list-0').click();
                               document.getElementById('save-list-1').click();
                               setOpenSaveConfirm(false)
                           }}
                           title={'저장 확인'}
                           content={'이미지 순서를 저장하시겠습니까?'}
            />
        </React.Fragment>
    )
}
import {Box, Dialog, DialogContent, DialogTitle, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    dialogQr: {
        '& .MuiDialog-paper': {
            minWidth: 300,
            maxWidth: 320,
            minHeight: 390
        }
    },
    dialogTitle: {
        display: 'flex',
        alignItems: 'center',
        minHeight: 70,
        color: '#222',
        fontWeight: 700,
        fontSize: 20,
        padding: '16px 30px'
    },
    dialogContent: {
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        '& > button': {
            width: 127,
            height: 46,
            boxShadow: 'none',
            textTransform: 'none',
            fontWeight: 'bold',
            marginTop: 10
        }
    },
    dialogText: {
        fontWeight: 'bold',
        fontSize: 14,
        lineHeight: '21px',
        maxWidth: 176,
        textAlign: 'center'
    },
    container: {
        margin: '20px 63px',
        padding: 5,
        background: '#3C39CB',
        borderRadius: 6,
        '& > p': {
            color: '#FFFFFF',
            padding: '7px 0',
            fontWeight: 400,
            fontSize: 12,
            display: 'flex',
            justifyContent: 'center',
            whiteSpace: 'break-spaces'
        }
    },
    qrCanvas: {
        padding: 18,
        background: '#FFFFFF',
        borderRadius: 6
    }
}));

export default function QrPage(props) {

    const {open, close, gccs, onLogin, onError} = props;
    const classes = useStyles();
    const [countDown, setCountDown] = useState(null);
    const [canvasUpdate, setCanvasUpdate] = useState(false);

    useEffect(() => {
        if (open) {
            if (!canvasUpdate) setCanvasUpdate(true);
            if (canvasUpdate) {
                gccs.setQrTimer((time) => {
                    setCountDown(time);
                });

                gccs.requestQrCallback(document.getElementById("qrCanvas"), (data) => {
                    onLogin(data);
                }, (errorCode, errorMsg) => {
                    onError(errorMsg);
                });
            }
        } else if (canvasUpdate) {
            setCanvasUpdate(false);
        }
    }, [open, canvasUpdate]);

    return (
        <Dialog open={open}
                onClose={close}
                className={classes.dialogQr}>
            <DialogTitle className={classes.dialogTitle}>QR Authentication</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Box display="flex" flexDirection="column" justifyContent="center" className={classes.container}>
                    <Box display="flex" justifyContent="center" className={classes.qrCanvas}>
                        <canvas id="qrCanvas"/>
                    </Box>
                    <Typography variant='body1'>
                        Time : <b>{countDown ?? 60}</b>
                    </Typography>
                </Box>
                <Typography className={classes.dialogText}>Please scan the QR code on your mobile.</Typography>
            </DialogContent>
        </Dialog>
    )
}
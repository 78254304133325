import CodeListPage from "./CodeListPage";
import CodeGroupListPage from "./CodeGroupListPage";
import React, {useEffect, useState} from "react";
import {useHistory} from 'react-router';
import {Route, Switch, useLocation, useRouteMatch} from 'react-router-dom';
import ReorderPage from "../../components/ReorderPage";

export default function CodeSettings() {

    const history = useHistory();
    let {path, url} = useRouteMatch();
    const {state} = useLocation();
    const [menuId, setMenuId] = useState(state.menuIdx);

    function onPageChange(pageIdx, codeGroup) {
        if (pageIdx === 1) {
            history.push(`${url}/list`, {codeGroup: codeGroup, menuIdx: menuId, type: "code"})
        } else if (pageIdx === 2) {
            history.push(`${url}/list/reorder`, {groupSeq: codeGroup.seq,  menuIdx: menuId, type: "code"})
        }
    }

    useEffect(() => {
        setMenuId(state.menuIdx);
    }, []);

    return (
        <React.Fragment>
            <Switch>
                <Route exact path={path}
                       render={props => (<CodeGroupListPage {...props} openCodePage={onPageChange}/>)}/>
                <Route exact path={`${path}/list`}
                       render={props => (<CodeListPage {...props} openCodePage={onPageChange}/>)}/>
                <Route exact path={`${path}/list/reorder`} render={
                       props => (
                            <ReorderPage target={"commonCode"}
                                         headerTitle={"공통코드 관리"}
                                         headerDescription={"공통코드의 순서를 변경할 수 있는 페이지입니다."}
                                         searchPlaceholder={"공통코드 내 검색"}
                                         {...props}/>
                       )}>
                </Route>
            </Switch>
        </React.Fragment>
    );
}
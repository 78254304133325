import usePagination from '@mui/material/usePagination';
import { styled } from '@mui/material/styles';
import {useStores} from "../common/store";
import {Button} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    arrowBtn: {
        backgroundColor: 'transparent',
        border: 'none',
        color: theme.palette.primary.main,
        minWidth: 32,
        maxHeight: 32,
        margin: 8,
        fontWeight: 900,
        padding: '6px 0',
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: 'transparent'
        },
        '&:disabled': {
            color: '#9FA5B6',
        }
    },
    pageBtn: {
        fontWeight: 'bold',
        fontSize: 14,
        minWidth: 32,
        maxWidth: 32,
        borderRadius: 5,
        border: 'none',
        height: 32,
        margin: 7,
        padding: 0
    }
}));

const List = styled('ul')({
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex'
});

export default function Pagination({totalPages, currentPage, onChange}) {

    const {ds} = useStores();
    const classes = useStyles();
    const { items } = usePagination({
        count: totalPages,
        page: currentPage
    });

    const changePage = (event, type, page) => {
        if (type === 'previous') {
            onChange(currentPage - 1)
        } else if (type === 'next') {
            onChange(currentPage + 1)
        } else if (type === "page") {
            onChange(page);
        }
    }

    return (
        <nav>
            <List>
                {items.map(({ page, type, selected, ...item }, index) => {
                    let children = null;
                    if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                        children = <span>···</span>;
                    } else if (type === 'page') {
                        children = (
                            <Button
                                type="button" {...item}
                                onClick={(e) => changePage(e, type, page)}
                                style={{
                                    backgroundColor: selected ? ds.theme.palette.primary.main : 'transparent',
                                    color: selected ? '#fff' : '#9FA5B6'
                                }}
                                className={classes.pageBtn}
                            >
                                {page}
                            </Button>
                        );
                    } else if (totalPages > 1) {
                        children = (
                            <Button type="button" {...item}
                                    onClick={(e) => changePage(e, type)}
                                    className={classes.arrowBtn}
                            >
                                {type==="previous" ? "<" : ">"}
                            </Button>
                        );
                    }
                    return <li key={index} style={{display: 'flex', alignItems: 'center'}}>{children}</li>;
                })}
            </List>
        </nav>
    );
}
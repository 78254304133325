import codes from "./codes.json";

export class ErrCode {
    static RT_SUCCESS = 0;
}

export class UserType {
    static FnsManager = 'CMMMCL001';
    static VendorManager = 'CMMMCL002';
    static ClientManager = 'CMMMCL003';
    static User = 'CMMMCL004';

    static isSuperManager = (userType) => {
        return UserType.FnsManager === userType;
    };

    static isVendorManager = (userType) => {
        return (UserType.VendorManager === userType);
    }

    static isClientManager = (userType) => {
        return (UserType.ClientManager === userType);
    }

    static isManager = (userType) => {
        return (UserType.FnsManager === userType) || (UserType.VendorManager === userType) || (UserType.ClientManager === userType);
    };

    static isUser = (userType) => {
        return (UserType.User === userType);
    };
}

export class User {
    seq = null;
    userKey = null;
    clientKey = null;
    clientName = null;
    userType = null;
    userStatus = null;
    phoneNum = null;
    name = null;
    email = null;
    empNo = null;
    authType = null;
    regDt = null;
    clientList = null;
    accessibleClient = null;
    deviceList = null;

    constructor(userData) {
        Object.keys(userData)
            .filter(key => key in this)
            .forEach(key => {
                this[key] = userData[key];
            });
    }

    isSuperManager = () => {
        return UserType.isSuperManager(this.userType);
    };

    isVendorManager = () => {
        return UserType.isVendorManager(this.userType);
    }

    isClientManager = () => {
        return UserType.isClientManager(this.userType);
    }

    isManager = () => {
        return UserType.isManager(this.userType);
    };

    isUser = () => {
        return UserType.isUser(this.userType);
    };

    getAccessClientUserType = (clientKey) => {
        let accessUserType = null;

        if (this.accessibleClient !== undefined && this.accessibleClient !== null) {
            if (this.accessibleClient[clientKey] !== undefined) {
                if (this.accessibleClient[clientKey].clientKey === clientKey) {
                    accessUserType = this.accessibleClient[clientKey].userType;
                }
            }
        }

        return accessUserType;
    }

}

export class CommonCodeValue {

    static getName = (key) => {
        if (key === undefined || key === null || key === "") {
            return null;
        }
        if (codes.defaultCodes[key] !== undefined) {
            return codes.defaultCodes[key];
        } else {
            return key;
        }
    };

    static getErrMsg = (key) => {
        if (key === undefined || key === null || key === "") {
            return null;
        }
        if (codes.errorCodes[key] !== undefined) {
            return codes.errorCodes[key];
        } else {
            return key;
        }
    };
}

export class BoardType extends CommonCodeValue {
    static bulletin = 'CMMCTP001';
    static gallery = 'CMMCTP002';
}

export class OptionType extends CommonCodeValue {
    static content = 'CMMCOP001';
    static image = 'CMMCOP002';
    static URL = 'CMMCOP003';
    static eventDate = 'CMMCOP004';
    static file = 'CMMCOP005';
}

export class UseType extends CommonCodeValue {
    static used = 'CMMUSE001';
    static notUsed = 'CMMUSE002';
}

export class VisibleType extends CommonCodeValue {
    static visible = 'CMMVSB001';
    static notVisible = 'CMMVSB002';
}

export class ConditionType extends CommonCodeValue {
    static required = 'CMMREQ001';
    static optional = 'CMMREQ002';
}

export class CompletionType extends CommonCodeValue {
    static completed = 'CMMCMP001';
    static notCompleted = 'CMMCMP002';
}

export class Language extends CommonCodeValue {
    static Korean = 'CMMLNG001';
    static English = 'CMMLNG002';
}

export class SortType extends CommonCodeValue {
    static sortByAsc  = 'CMMORD001';
    static sortByDesc = 'CMMORD002';
}

import React, {useEffect, useState} from 'react';
import {createStyles, makeStyles} from "@mui/styles";
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle, FormControlLabel,
    FormGroup,
    IconButton, Radio, RadioGroup,
    TextField,
    Typography
} from "@mui/material";
import api from "../../common/api";
import CustomCheckBox from "../../components/CustomCheckBox";
import {ReactComponent as CategoryIcon} from "../../images/icons/category_icon.svg";
import CloseIcon from '@mui/icons-material/Close';
import {BoardType, CommonCodeValue, ConditionType, OptionType, UseType} from "../../common/types";

const useStyles = makeStyles((theme) => createStyles({
    title: {
        display: 'flex',
        minHeight: 70,
        padding: '10px 10px 5px 30px',
        '& p': {
            color: theme.palette.text.title,
            fontWeight: 700,
            fontSize: '1.125rem',
            alignSelf: 'flex-end',
            marginBottom: 7
        }
    },
    icon: {
        height: 35,
        width: 35,
        background: theme.palette.primary.main,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'flex-end',
        marginRight: 10,
        '& > svg': {
            width: 15,
            height: 15,
        }
    },
    closeIcon: {
        flex: 1,
        display: 'flex',
        alignItems: 'flex-start',
        '& > .MuiIconButton-root': {
            padding: 0,
            width: 36,
            height: 36,
            '&:hover': {
                backgroundColor: theme.palette.secondary.light
            }
        }
    },
    content: {
        minWidth: 460,
        minHeight: 440,
        padding: '20px 30px 30px 30px',
        '& > :nth-child(-n+3) > p': {
            color: theme.palette.text.dark,
            fontWeight: 'bold',
            minWidth: 100
        }
    },
    categoryName: {
        minHeight: 40,
        marginBottom: 10,
        '& > :first-child': {
            marginTop: 12
        },
        '& .MuiOutlinedInput-root': {
            maxHeight: 40,
            minWidth: 300
        },
    },
    categoryDescription: {
        marginBottom: 20,
        '& > :first-child': {
            marginTop: 12
        },
        '& .MuiOutlinedInput-root': {
            height: 88,
            minWidth: 300,
            alignItems: 'flex-start'
        },
    },
    categoryTypes: {
        marginBottom: 10,
        '& > :last-child': {
            display: 'flex',
            flexDirection: 'row'
        }
    },
    categoryOptions: {
        marginBottom: 30,
        '& > *': {
            alignItems: 'center',
            height: 35,
            '& > .MuiTypography-root': {
                color: theme.palette.text.secondary,
                fontWeight: 'bold',
                minWidth: 100
            },
            '& > :nth-child(2)': {
                display: 'flex',
                flexDirection: 'row',
                '& > :first-child': {
                    marginRight: 40
                },
                '& .MuiTypography-root': {
                    fontWeight: 'bold'
                }
            }
        }
    },
    buttons: {
        justifyContent: 'flex-end',
        '& > *': {
            width: 127,
            height: 40,
            borderRadius: 5,
            boxShadow: 'none',
            fontWeight: 'bold',
            fontSize: 13,
            color: '#fff'
        },
        '& > :first-child': {
            backgroundColor: '#9FA5B6',
            marginRight: 6
        },
        '& > :last-child': {
            backgroundColor: theme.palette.primary.main
        }
    }
}));

export default function CategoryCreateDialog(props) {

    const {open, onClose} = props;
    const classes = useStyles();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [boardType, setBoardType] = useState(BoardType.bulletin);
    const [nameError, setNameError] = useState(false);

    const initialOptions = {
        contentUsed: false,
        contentRequired: false,
        imageUsed: false,
        imageRequired: false,
        urlUsed: false,
        urlRequired: false,
        eventDateUsed: false,
        eventDateRequired: false,
        fileUsed: false,
        fileRequired: false
    }
    const [options, setOptions] = useState({
        contentUsed: false,
        contentRequired: false,
        imageUsed: false,
        imageRequired: false,
        urlUsed: false,
        urlRequired: false,
        eventDateUsed: false,
        eventDateRequired: false,
        fileUsed: false,
        fileRequired: false
    });

    const {
        contentUsed, contentRequired,
        imageUsed, imageRequired,
        urlUsed, urlRequired,
        eventDateUsed, eventDateRequired,
        fileUsed, fileRequired} = options;

    const handleChange = (value, field) => {
        if (field === "name") {
            validateName(value);
        } else if (field === "description") {
            setDescription(value)
        }
    }
    function validateName(value) {
        if (value.trim() === "") {
            setNameError(true)
        } else {
            setNameError(false);
            setName(value);
        }
    }

    const handleTypeChange = (name) => {
        if (name === "bulletin") {
            setBoardType(BoardType.bulletin);
            setOptions(initialOptions);
        } else {
            setBoardType(BoardType.gallery);
            setOptions({
                contentUsed: false,
                contentRequired: false,
                imageUsed: true,
                imageRequired: true,
                urlUsed: false,
                urlRequired: false,
                eventDateUsed: false,
                eventDateRequired: false,
                fileUsed: false,
                fileRequired: false});
        }
    }

    const handleCheckUsed = (name, checked) => {
        if (name === "content") {
            if (!checked) {
                setOptions({...options, contentRequired: false})
            }
            setOptions({...options, contentUsed: checked});
        } else if (name === "image") {
            if (!checked) {
                setOptions({...options, imageRequired: checked})
            }
            setOptions({...options, imageUsed: checked})
        } else if (name === "url") {
            if (!checked) {
                setOptions({...options, urlRequired: checked})
            }
            setOptions({...options, urlUsed: checked})
        } else if (name === "eventDate") {
            if (!checked) {
                setOptions({...options, eventDateRequired: checked})
            }
            setOptions({...options, eventDateUsed: checked})
        } else if (name === "file") {
            if (!checked) {
                setOptions({...options, fileRequired: checked})
            }
            setOptions({...options, fileUsed: checked})
        }
    }

    const handleCheckRequired = (name, checked) => {
        if (name === "content") {
            setOptions({...options, contentRequired: checked})
        } else if (name === "image") {
            setOptions({...options, imageRequired: checked})
        } else if (name === "url") {
            setOptions({...options, urlRequired: checked})
        } else if (name === "eventDate") {
            setOptions({...options, eventDateRequired: checked})
        } else if (name === "file") {
            setOptions({...options, fileRequired: checked})
        }
    }

    const onSave = (e) => {
        e.preventDefault();

        let optionList = [];
        if (boardType === BoardType.bulletin) {
            if (options.contentUsed) {
                optionList.push({
                    optionType: OptionType.content,
                    useStatus: UseType.used,
                    required: options.contentRequired ? ConditionType.required : ConditionType.optional
                })
            }
            if (options.imageUsed) {
                optionList.push({
                    optionType: OptionType.image,
                    useStatus: UseType.used,
                    required: options.imageRequired ? ConditionType.required : ConditionType.optional
                })
            }
            if (options.urlUsed) {
                optionList.push({
                    optionType: OptionType.URL,
                    useStatus: UseType.used,
                    required: options.urlRequired ? ConditionType.required : ConditionType.optional
                })
            }
            if (options.eventDateUsed) {
                optionList.push({
                    optionType: OptionType.eventDate,
                    useStatus: UseType.used,
                    required: options.eventDateRequired ? ConditionType.required : ConditionType.optional
                })
            }
            if (options.fileUsed) {
                optionList.push({
                    optionType: OptionType.file,
                    useStatus: UseType.used,
                    required: options.fileRequired ? ConditionType.required : ConditionType.optional
                })
            }
        } else {
            optionList = [{
                optionType: OptionType.image,
                useStatus: UseType.used,
                required: ConditionType.required
            }]
        }

        api.addCategory(name, boardType, description, optionList)
            .then(() => {
                onClose("success")
            })
            .catch((err) => {
                console.log(CommonCodeValue.getErrMsg(err.rtCode))
                onClose()
            })
    }

    useEffect(() => {
        setNameError(false);
        setBoardType(BoardType.bulletin);
        setOptions({
            contentUsed: false,
            contentRequired: false,
            imageUsed: false,
            imageRequired: false,
            urlUsed: false,
            urlRequired: false,
            eventDateUsed: false,
            eventDateRequired: false,
            fileUsed: false,
            fileRequired: false
        });
    }, [open]);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle className={classes.title}>
                <Box className={classes.icon}><CategoryIcon/></Box>
                <Typography>카테고리 등록</Typography>
                <Box flex={1} justifyContent='flex-end' className={classes.closeIcon}>
                    <IconButton aria-label="close" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>

                <form id="create-form" className={classes.content} noValidate={false} autoComplete="off" onSubmit={onSave}>

                    <Box display='flex' alignItems='center' className={classes.categoryTypes}>
                        <Typography>카테고리 타입</Typography>
                        <RadioGroup defaultValue="게시판" onChange={e => handleTypeChange(e.target.name)}>
                            <FormControlLabel value="게시판" control={<Radio />} label="게시판" name="bulletin"/>
                            <FormControlLabel value="갤러리" control={<Radio />} label="갤러리" name="gallery"/>
                        </RadioGroup>
                    </Box>

                    <Box display='flex' className={classes.categoryName}>
                        <Typography>카테고리명</Typography>
                        <TextField id={'name'}
                                   defaultValue={''}
                                   required
                                   inputProps={{maxLength: 30}}
                                   error={nameError}
                                   helperText={nameError ? "필수 입력 항목입니다" : null}
                                   FormHelperTextProps={{error: nameError}}
                                   onChange={e => handleChange(e.target.value, "name")}/>
                    </Box>

                    <Box display='flex' className={classes.categoryDescription}>
                        <Typography>카테고리 설명</Typography>
                        <TextField defaultValue={''}
                                   required={false}
                                   multiline
                                   inputProps={{maxLength: 50}}
                                   onChange={e => handleChange(e.target.value, "description")}/>
                    </Box>

                    <Box display='flex' flexDirection='column' className={classes.categoryOptions}>
                        <Box display='flex'>
                            <Typography>본문</Typography>
                            <FormGroup>
                                <CustomCheckBox label='사용'
                                                name="content"
                                                checked={contentUsed}
                                                disabled={BoardType.getName(boardType) === "갤러리"}
                                                onChange={(value, name) => handleCheckUsed(name, value)}
                                />
                                <CustomCheckBox label='필수'
                                                name="content"
                                                checked={!contentUsed ? false : contentRequired}
                                                disabled={!contentUsed || BoardType.getName(boardType) === "갤러리"}
                                                onChange={(value, name) => handleCheckRequired(name, value)}
                                />
                            </FormGroup>
                        </Box>
                        <Box display='flex'>
                            <Typography>이미지</Typography>
                            <FormGroup>
                                <CustomCheckBox label='사용'
                                                name="image"
                                                checked={BoardType.getName(boardType) === "갤러리" ? true : imageUsed}
                                                disabled={BoardType.getName(boardType) === "갤러리"}
                                                onChange={(value, name) => handleCheckUsed(name, value)}
                                />
                                <CustomCheckBox label='필수'
                                                name="image"
                                                checked={BoardType.getName(boardType) === "갤러리" ? true : !imageUsed ? false : imageRequired}
                                                disabled={!imageUsed || BoardType.getName(boardType) === "갤러리"}
                                                onChange={(value, name) => handleCheckRequired(name, value)}
                                />
                            </FormGroup>
                        </Box>
                        <Box display='flex'>
                            <Typography>URL</Typography>
                            <FormGroup>
                                <CustomCheckBox label='사용'
                                                name="url"
                                                checked={urlUsed}
                                                disabled={BoardType.getName(boardType) === "갤러리"}
                                                onChange={(value, name) => handleCheckUsed(name, value)}
                                />
                                <CustomCheckBox label='필수'
                                                name="url"
                                                checked={!urlUsed ? false : urlRequired}
                                                disabled={!urlUsed || BoardType.getName(boardType) === "갤러리"}
                                                onChange={(value, name) => handleCheckRequired(name, value)}
                                />
                            </FormGroup>
                        </Box>
                        <Box display='flex'>
                            <Typography>이벤트 날짜</Typography>
                            <FormGroup>
                                <CustomCheckBox label='사용'
                                                name="eventDate"
                                                checked={eventDateUsed}
                                                disabled={BoardType.getName(boardType) === "갤러리"}
                                                onChange={(value, name) => handleCheckUsed(name, value)}
                                />
                                <CustomCheckBox label='필수'
                                                name="eventDate"
                                                checked={!eventDateUsed ? false : eventDateRequired}
                                                disabled={!eventDateUsed || BoardType.getName(boardType) === "갤러리"}
                                                onChange={(value, name) => handleCheckRequired(name, value)}
                                />
                            </FormGroup>
                        </Box>
                        <Box display='flex'>
                            <Typography>파일</Typography>
                            <FormGroup>
                                <CustomCheckBox label='사용'
                                                name="file"
                                                checked={fileUsed}
                                                disabled={BoardType.getName(boardType) === "갤러리"}
                                                onChange={(value, name) => handleCheckUsed(name, value)}
                                />
                                <CustomCheckBox label='필수'
                                                name="file"
                                                checked={!fileUsed ? false : fileRequired}
                                                disabled={!fileUsed || BoardType.getName(boardType) === "갤러리"}
                                                onChange={(value, name) => handleCheckRequired(name, value)}
                                />
                            </FormGroup>
                        </Box>
                    </Box>


                    <Box display='flex' className={classes.buttons}>
                        <Button onClick={onClose} variant="contained" color="secondary">취소</Button>
                        <Button type='submit' variant="contained">저장</Button>
                    </Box>
                </form>
            </DialogContent>
        </Dialog>
    )
}
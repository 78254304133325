import React, {useEffect, useRef, useState} from 'react';
import {useStores} from '../common/store';
import { Observer } from 'mobx-react';
import {Redirect} from 'react-router-dom';
import storage, {StorageKey as SK} from '../common/storage';
import {
    Alert,
    Box,
    Button,
    Checkbox,
    Dialog, DialogContent, DialogTitle,
    FormControlLabel,
    InputAdornment,
    TextField,
    Typography
} from "@mui/material";
import api from "../common/api";
import EmailIcon from '@mui/icons-material/Email';
import UncheckedIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckedIcon from '@mui/icons-material/CheckBox';
import QrCodeIcon from '@mui/icons-material/QrCode';
import PasswordIcon from '@mui/icons-material/Password';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import QrPage from "./QrPage";
import OtpPage from "./OtpPage";
import {ReactComponent as Logo} from "../images/logo.svg";
import loginBg from "../images/login_bg.jpg";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    root: {
        padding: '0 !important',
        backgroundColor: '#F5F5F8',
        backgroundImage: `url(${loginBg})`,
        backgroundSize: 'cover',
        display: "flex",
        minWidth: '100vw',
        minHeight: '100vh'
    },
    loginContainer: {
        display: "flex",
        flexDirection: 'column',
        maxWidth: '540px',
        background: 'rgba(5, 4, 7, 0.3)',
        backgroundSize: 'cover',
        padding: 50,
    },
    logoImg: {
        alignSelf: "flex-start",
        marginTop: 80,
        marginBottom: 60,
        width: '196px',
        height: '41px',
    },
    form: {
        flexDirection: "column",
        alignSelf: "flex-start",
        width: '420px',
        maxHeight: "100vh",
    },
    userName: {
        color: '#fff',
        fontSize: "1.25rem",
        fontWeight: 600,
        paddingBottom: 10
    },
    loginBtn: {
        fontSize: '1rem',
        fontWeight: 700,
        height: '65px',
        width: "420px",
        borderRadius: "10px",
        color: '#fff !important',
        background: '#3D39CC  !important',
        '&:hover': {
            background: '#3C58E9 !important',
        },
    },
    loginLabel: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 30,
        '& .MuiSvgIcon-root': {
            fill: '#435F95'
        }
    },
    inputField: {
        height: '65px',
        width: '420px',
        margin: '13px 0 18px 0',
        '& .MuiOutlinedInput-root': {
            color: '#fff',
            background: 'transparent',
            '& fieldset': {
                borderColor: '#fff',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#fff',
                borderWidth: '1px'
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #fff",
                color: '#fff'
            }
        },
        '& .MuiInputAdornment-root': {
            paddingLeft: "10px",
            color: "white"
        },
        '& .MuiOutlinedInput-input': {
            '&::placeholder': {
                color: '#5E6066',
                opacity: 1
            }
        },
        '& .MuiInputBase-root.Mui-disabled': {
            border: '1px solid #DBDADA'
        }
    },
    inputText: {
        height: "65px",
        padding: 0,
        fontSize: "0.875rem",
        "&:-webkit-autofill": {
            transitionDelay: "9999s",
            WebkitBoxShadow: "0 0 0 100px rgba(0,0,0,0) inset"
        },
    },
    rememberLabel: {
        width: '180px',
        '& .MuiTypography-body1': {
            color: '#fff',
            fontWeight: 500,
            fontSize: "0.875rem"
        }
    },
    rememberLabelChecked: {
        width: '180px',
        '& .MuiTypography-body1': {
            color: '#fff',
            fontWeight: 500,
            fontSize: "0.875rem",
        }
    },

    orLine: {
        display: "grid",
        gridTemplateColumns: "1fr 60px 1fr",
        gridTemplateRows: "1fr",
        justifyContent: "center",
        height: "16px",
        width: "420px",
        margin: "50px 0px 50px 0px",
    },
    orText: {
        display: "inline-grid",
        alignItems: "center",
        textAlign: "center",
        fontSize: "0.875rem",
        fontWeight: 500,
        width: "60px",
        color: '#F5F5F8'
    },
    hrStyle: {
        display: "inline-grid",
        margin: 'auto 0px',
        width: "180px",
        border: '1px solid #F5F5F8 0.7'
    },

    subLoginBtnBox: {
        display: 'flex',
        alignItems: "center",
        width: "420px",
        flex: 1,
        '& > :first-child': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
        },
        '& > :nth-child(2)': {
            borderRadius: 0,
            marginLeft: '-1px',
        },
        '& > :last-child': {
            marginLeft: '-1px',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0
        },
        '& > button, .Mui-disabled': {
            display: 'flex',
            flex: 1,
            color: '#FFFFFF',
            backgroundColor: 'transparent',
            border: "1px solid #322F38",
            boxSizing: 'border-box',
            borderRadius: 5,
            boxShadow: 'none',
            height: "45px",
            margin: "0",
            transition: 'none !important'
        },
        '& > button:hover': {
            backgroundColor: "transparent",
            border: '1px solid #F5F5F8',
            zIndex: 1
        }
    },
    subLoginBtnLabel: {
        justifyContent: "center",
    },
    subLoginBtnIcon: {
        minWidth: "18.5px",
        minHeight: "18.5px",
        marginRight: "9.32px"
    },
    introText: {
        marginLeft: "150px",
        marginTop: "250px",
        '& > :first-child': {
            color: 'rgba(255, 255, 255, 0.6)',
            lineHeight: "20.7px",
            fontSize: '1.125rem',
            fontWeight: 500,
            marginBottom: '20px'
        },
        '& > :nth-child(2)': {
            color: '#fff',
            lineHeight: "52px",
            fontSize: '2rem',
            fontWeight: 300
        },
        '& > :nth-child(3)': {
            color: '#fff',
            lineHeight: "52px",
            fontSize: '2rem',
            fontWeight: 700
        }
    },

    alert: {
        height: "3.5rem",
        width: '420px',
        fontSize: "0.688rem"
    },
    description: {
        color: '#fff',
        fontSize: '0.9375rem',
        lineHeight: '1.5625rem',
        marginRight: "10px !important"
    },
    progressAlert: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '& > p': {
            fontSize: '0.9rem',
        },
        '& > button': {}
    },
    dialogOtp: {
        '& .MuiDialog-paper': {
            minWidth: 450,
            minHeight: 280,
        },
        '& .MuiDialogContent-root': {
            padding: '0 30px',
            '& > p': {
                alignSelf: 'flex-start'
            }
        }
    },
    dialogTitle: {
        display: 'flex',
        alignItems: 'center',
        minHeight: 70,
        color: '#222',
        fontWeight: 700,
        fontSize: 20,
        padding: '16px 30px'
    },
    dialogContent: {
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        '& > button': {
            width: 127,
            height: 46,
            boxShadow: 'none',
            textTransform: 'none',
            fontWeight: 'bold',
            marginTop: 10
        }
    },
    totpField: {
        margin: '20px 0',
        '& input': {
            height: 50,
            fontSize: 25,
            padding: '0 18px',
            borderRadius: 5,
            border: '1px solid #DBDADA',
            '&:hover': {
                border: '1px solid #3C39CB',
            },
            '&:focus-visible': {
                border: '1px solid #3C39CB',
                outline: 'none !important'
            }
        }
    },
    dialogText: {
        fontWeight: 'bold',
        fontSize: 14,
        lineHeight: '21px',
        maxWidth: 176,
        textAlign: 'center'
    },
    dialogBtn: {
        '& > button': {
            width: 127,
            height: 46,
            boxShadow: 'none',
            textTransform: 'none',
            fontWeight: 'bold',
            marginTop: 10
        },
        '& > :first-child': {
            background: '#B3B6D0',
            marginRight: 6
        },
        '& > :last-child': {
            background: '#3C39CB',
        }
    }
});

function StatusAlert({status, onClose}) {
    return (
        <Alert severity="error" onClose={onClose}>{status}</Alert>
    );
}

function ProgressAlert({status}) {
    return (
        <Alert severity="info">{status}</Alert>
    );
}

function LoginForm(props) {

    const {ds} = useStores();
    const {gccs} = props;
    const classes = useStyles();
    const masterClientKey = api.CLIENT_KEY;
    const [userKey, setUserKey] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [errMsg, setErrMsg] = useState(null);
    const [progressMsg, setProgressMsg] = useState(null);
    const [countDown, setCountDown] = useState(null);

    const [qrPageOpen, setQrPageOpen] = useState(false);
    const [otpOpen, setOtpOpen] = useState(false);
    const [totpOpen, setTotpOpen] = useState(false);
    const totpCode = useRef(null);

    const onUserKeyChange = (event) => {
        setUserKey(event.target.value);
    };
    const onRememberMe = (event) => {
        setRememberMe(event.target.checked);
    };

    const onErrorHandler = () => {
        setErrMsg(null);
        setProgressMsg(null);
    }

    const isError = errMsg != null;
    const isProcess = (progressMsg != null) && (!isError);

    const onLogin = () => {

        // token login
        // api.setToken('ewogICJhbGciOiAiSFMzODQiCn0.ewogICJ1c2VyS2V5IjogImlyaW5hIiwKICAiY2xpZW50U2VxIjogMTYsCiAgImNsaWVudEtleSI6ICJkNDI2NjNlYzQyMGU0MmIzODZmOTUwMDJhMTA4NTZmZCIsCiAgInVzZXJUeXBlIjogIkNNTU1DTDAwMyIsCiAgImFjY2Vzc2libGVDbGllbnQiOiB7CiAgICAiZDQyNjYzZWM0MjBlNDJiMzg2Zjk1MDAyYTEwODU2ZmQiOiAiQ01NTUNMMDAzIgogIH0sCiAgImV4cCI6IDE2NDczOTE3NjkKfQ.gPAcX5JFBJmWwPcrna2hXuYqLO_kY3KaFsCQL1Iw3GPzxmz38VSgeY-9iPsITlIA');
        // api.getMe()
        //     .then(data => {
        //         ds.setLogin(true);
        //         storage.setSession(SK.LOGIN_USER_INFO, {data});
        //     })
        //     .catch(err => {
        //         setErrMsg(err.rtMsg);
        //     });
        // return;

        if (userKey == null || userKey.length <= 0) {
            setErrMsg("사용자 ID를 입력해주세요");
            return;
        }
        if (rememberMe) {
            storage.setLocal(SK.LOGIN_INFO, {masterClientKey, userKey, rememberMe});
        } else {
            storage.removeLocal(SK.LOGIN_INFO);
        }

        gccs.requestAuthCallback(userKey, (data) => {
            onLoginProcess(data, progressMsg);
        }, (errorCode, errorMsg) => {
            setErrMsg(errorMsg);
        });

        gccs.setAuthTimer((time) => {
            setCountDown(time);
        });

        gccs.setAuthMessage((message) => {
            setProgressMsg(message);
        });
    };

    const onLoginProcess = (token) => {
        api.setToken(token);
        api.getMe()
            .then(data => {
                const userType = ds.getUserType();
                const userTypeNum = parseInt(userType.substring(userType.lastIndexOf("L") + 1));
                if (userTypeNum > 3) {
                    setErrMsg("권한이 부족합니다");
                    storage.removeSession(SK.ACCESS_TOKEN);
                } else {
                    ds.setLogin(true);
                    storage.setSession(SK.LOGIN_USER_INFO, {data});
                }
            })
    }

    const onCancel = () => {
        gccs.onCancel(userKey, (errorCode, errorMsg) => {
            setErrMsg(errorMsg);
        });
    }

    const onQrLogin = () => {
        setQrPageOpen(true);
        gccs.setQrMessage((message) => {
            setProgressMsg(message)
        });
    }

    const onQrError = (message) => {
        setErrMsg(message)
    }

    const onQrCancel = () => {
        setQrPageOpen(false);
        gccs.onQrCancel(document.getElementById("qrCanvas"), (errorCode, errorMsg) => {
            onQrError(errorMsg);
        });
    }

    const onOtpLogin = () => {
        setOtpOpen(true);
        gccs.setOtpMessage((message) => {
            setProgressMsg(message)
        });
    }

    const onOtpError = (message) => {
        setErrMsg(message)
    }
    const onOtpCancel = () => {
        setOtpOpen(false);
    }
    const onOtpClose = () => {
        setOtpOpen(false)
        gccs.setOtpTimer((time) => {
            setCountDown(time);
        });
    }

    const onTotpLogin = () => {
        if (userKey == null || userKey.length <= 0) {
            setErrMsg("사용자 ID를 입력해주세요");
            return;
        }
        if (rememberMe) {
            storage.setLocal(SK.LOGIN_INFO, {masterClientKey, userKey, rememberMe});
        } else {
            storage.removeLocal(SK.LOGIN_INFO);
        }
        setTotpOpen(true);
    }

    const handleTotpInput = (value) => {
        totpCode.current = value;
    }

    const verifyTotp = () => {
        if (totpCode.current === null || totpCode.current.length !== 6) {
            alert("Please enter a 6-digit totp code.");
            return;
        }
        gccs.requestTotpCallback(userKey, totpCode.current, (data) => {
            onLoginProcess(data);
            totpCode.current = null;
        }, (errorCode, errorMsg) => {
            setErrMsg(errorMsg);
            setTotpOpen(false);
            totpCode.current = null;
        });
    }

    useEffect(() => {
        const info = storage.getLocal(SK.LOGIN_INFO);
        if (info && info.rememberMe) {
            setUserKey(info.userKey);
            setRememberMe(info.rememberMe);
        }
    }, []);

    function OtpDialog() {
        return (
            <Dialog open={otpOpen} onClose={onOtpCancel} className={classes.dialogOtp}>
                <DialogTitle  className={classes.dialogTitle}>OTP Authentication</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Typography>Please check the verification code on your mobile.</Typography>
                    <OtpPage gccs={gccs}
                             onLogin={onLoginProcess}
                             onError={onOtpError}
                             onClose={onOtpClose}
                             onOtpVerifySuccess={setUserKey}
                    />
                    <Box display='flex' className={classes.dialogBtn}>
                        <Button
                            size='small'
                            variant="contained"
                            onClick={onOtpCancel}
                        >Cancel</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }

    function TotpDialog() {
        return (
            <Dialog open={totpOpen} onClose={() => setTotpOpen(false)} className={classes.dialogOtp}>
                <DialogTitle className={classes.dialogTitle}>TOTP Authentication</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Typography>Please check the verification code on your mobile.</Typography>
                    <Box className={classes.totpField}>
                        <TextField id="totpInput"
                                    onChange={(e) => handleTotpInput(e.target.value)}
                                    onKeyPress={
                                        event => {
                                        if (event.key === 'Enter') {
                                            verifyTotp();
                                        }}}
                        />
                    </Box>
                    <Box display='flex' className={classes.dialogBtn}>
                        <Button
                            size='small'
                            variant="contained"
                            onClick={() => setTotpOpen(false)}
                        >Cancel</Button>
                        <Button
                            size='small'
                            variant="contained"
                            onClick={() => verifyTotp()}
                        >Ok</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <Observer>{() => {
            return (
                <Box className={classes.root}>
                    <Box className={classes.loginContainer}>
                        <Box display='flex' className={classes.logoImg}>
                            <Logo/>
                        </Box>
                        <Box display='flex' className={classes.form}>
                            <Typography className={classes.userName}>User Name</Typography>
                            <TextField variant="outlined"
                                       required
                                       fullWidth
                                       id="userKey"
                                       name="userKey"
                                       value={userKey}
                                       disabled={isProcess}
                                       onChange={onUserKeyChange}
                                       onKeyPress={(e) => {
                                           e.key === 'Enter' && onLogin()
                                       }}
                                       color="primary"
                                       placeholder={"Enter your ID"}
                                       className={classes.inputField}
                                       inputProps={{className: classes.inputText}}
                                       InputProps={{
                                           startAdornment: (
                                               <InputAdornment position="start">
                                                   <EmailIcon fontSize="small"/>
                                               </InputAdornment>
                                           )
                                       }}
                            />
                            <Box className={classes.loginLabel}>
                                <FormControlLabel
                                    control={<Checkbox icon={<UncheckedIcon/>}
                                                       checkedIcon={<CheckedIcon/>}
                                                       checked={rememberMe}
                                                       onChange={onRememberMe}
                                                       color='primary' disabled={isProcess}/>}
                                    className={(rememberMe === true ? classes.rememberLabelChecked : classes.rememberLabel)}
                                    label={"Remember me"}
                                />
                                {countDown !== null &&
                                    <Box className={classes.progressAlert}>
                                        <Typography variant='body1' className={classes.description}>남은 시간
                                            : {countDown}초</Typography>
                                        <Button size='small' variant="contained" color="primary"
                                                onClick={onCancel}>Cancel</Button>
                                    </Box>}
                            </Box>
                            <Box>
                                <Button fullWidth size='large'
                                        disabled={isProcess}
                                        onClick={isProcess ? onCancel : onLogin}
                                        className={classes.loginBtn}
                                >LOGIN</Button>
                                <Box className={classes.orLine}>
                                    <hr className={classes.hrStyle}/>
                                    <Typography className={classes.orText}>or</Typography>
                                    <hr className={classes.hrStyle}/>
                                </Box>
                                <Box className={classes.subLoginBtnBox}>
                                    <Button size='large' variant="contained"
                                            onClick={isProcess ? onQrCancel : onQrLogin}>
                                        <QrCodeIcon className={classes.subLoginBtnIcon}/>
                                        <div className={classes.subLoginBtnLabel}>QR</div>
                                    </Button>
                                    <QrPage open={qrPageOpen}
                                            close={onQrCancel}
                                            gccs={gccs}
                                            onLogin={onLoginProcess}
                                            onError={onQrError}
                                    />
                                    <Button
                                        size='large'
                                        variant="contained"
                                        onClick={isProcess ? onOtpCancel : onOtpLogin}
                                    >
                                        <PasswordIcon className={classes.subLoginBtnIcon}/>
                                        <div className={classes.subLoginBtnLabel}>OTP</div>
                                    </Button>
                                    <OtpDialog/>
                                    <Button
                                        size='large'
                                        variant="contained"
                                        onClick={isProcess ? () => setTotpOpen(false) : onTotpLogin}
                                    >
                                        <AccessTimeIcon className={classes.subLoginBtnIcon}/>
                                        <div className={classes.subLoginBtnLabel}>TOTP</div>
                                    </Button>
                                    <TotpDialog/>
                                </Box>
                            </Box>
                            <Box mt={3} hidden={!isError} className={classes.alert}>
                                <StatusAlert status={errMsg} onClose={onErrorHandler}/>
                            </Box>
                            <Box mt={3} hidden={!isProcess} className={classes.alert}>
                                <ProgressAlert status={progressMsg} count={countDown}/>
                            </Box>
                            <Box mt={3} hidden={isProcess || isError} className={classes.alert}/>
                        </Box>
                    </Box>
                    <Box className={classes.introText}>
                        <Typography>Next Generation IT Authentication Security</Typography>
                        <Typography>We ensure your safety in the digital world through</Typography>
                        <Typography>easy, fast and secure solution BSA</Typography>
                    </Box>
                </Box>
            )}}
    </Observer>)
}

export default function LoginPage(props) {
    const {ds} = useStores();
    const gccs = new Guardian(api.CLIENT_KEY);

    return (
        <Observer>{() =>
            {
                if (ds.isLogin) {
                    const {location} = props;
                    const {from} = location.state || {from: {pathname: '/'}};
                    const userType = ds.getUserType();
                    const userTypeNum = parseInt(userType.substring(userType.lastIndexOf("L") + 1));
                    if (userTypeNum > 3) {
                        return <LoginForm {...props} gccs={gccs}/>
                    }
                    return <Redirect to={from}/>;
                } else {
                    return <LoginForm {...props} gccs={gccs}/>;
                }
            }}
        </Observer>);
}

import React, {useEffect, useState} from "react";
import {Box, Button, Typography} from "@mui/material";
import {ReactComponent as Logo} from "../images/logo.svg";
import {ReactComponent as BulletinIcon} from "../images/icons/bulletin_icon.svg";
import {ReactComponent as GalleryIcon} from "../images/icons/gallery_icon.svg";
import {ReactComponent as CategoryIcon} from "../images/icons/category_icon.svg";
import {ReactComponent as CommonCodeIcon} from "../images/icons/common_code_icon.svg";
import {ReactComponent as AvatarIcon} from "../images/icons/avatar.svg";
import {ReactComponent as HomepageImage} from "../images/homepage_link.svg";
import {createStyles, makeStyles} from "@mui/styles";
import {BoardType} from "../common/types";
import {useStores} from "../common/store";
import {ReactComponent as LogoutIcon} from "../images/icons/logout_icon.svg";
import storage, {StorageKey as SK} from "../common/storage";
import {useLocation} from "react-router-dom";

const useStyles = makeStyles((theme) => createStyles({
    leftMenu: {
        maxWidth: 297,
        height: '100vh',
        minHeight: 880,
        padding: '0 35px',
        backgroundColor: theme.palette.primary.main
    },
    logoPng: {
        margin: "65px 0 45px 0",
        '& > svg': {
           width: 179
        }
    },
    userInfo: {
        height: 76,
        borderRadius: 10,
        backgroundColor: 'rgba(241, 248, 255, 0.1)',
        marginBottom: 25,
        paddingLeft: 25,
        '& > svg': {
            marginTop: 5
        }
    },
    profile: {
        color: '#fff',
        marginLeft: 10,
        '& > :first-child': {
            fontFamily: 'Source Sans Pro',
            fontSize: 12,
            fontWeight: 'bold',
            lineHeight: '12.87px',
            marginBottom: 8,
            maxWidth: 120,
            wordBreak: 'break-all'
        },
        '& > :last-child': {
            fontFamily: 'Nanum Gothic',
            fontSize: 11,
            fontWeight: 'bold',
            lineHeight: '11px',
            display: 'flex',
            alignItems: 'center',
            '& > svg': {
                marginLeft: 7
            },
            '&:hover': {
                cursor: 'pointer'
            }
        },
    },
    menuList: {
        color: theme.palette.text.white,
        '& > *': {
            fontSize: 12,
            height: 41,
            padding: '15px 30px',
            display:'flex',
            alignItems: 'center',
            '&:hover': {
                cursor: 'default'
            }
        }
    },
    menuItem: {
        fontSize: 12,
        fontWeight: 700,
        borderRadius: 12,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    menuItemSelected: {
        fontSize: 12,
        fontWeight: 700,
        borderRadius: 10,
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.primary.main,
        '& svg > path': {
            fill: theme.palette.primary.main,
        },
        '&:hover': {
            cursor: 'pointer'
        }
    },
    menuIcon: {
        marginRight: 20
    },
    homepageBlock: {
        alignSelf: 'flex-end',
        flex: 1,
        marginBottom: 45
    },
    homepageBanner: {
        position: 'relative',
        '& > :nth-child(2)': {
            position: 'absolute',
            marginTop: 170,
            '& > :first-child': {
                fontSize: 12,
                lineHeight: '12.87px',
                fontWeight: 700,
                color: '#47484D',
                marginBottom: 10
            },
            '& > :nth-child(2)': {
                fontSize: 11,
                lineHeight: '10.89px',
                fontWeight: 700,
                color: '#9FA5B6',
                marginBottom: 20
            }
        },
        '& > svg': {
            width: 227
        }
    },
    homepageBtn: {
        '& > *': {
            backgroundColor: 'rgba(26, 56, 112, 0.9)',
            color: '#fff',
            borderRadius: 10,
            height: 30,
            width: 90,
            fontSize: 11,
            fontWeight: 'bold',
            textTransform: 'none',
            padding: 6,
            boxShadow: 'none',
            '&:hover': {
                backgroundColor: 'rgba(26, 56, 112, 0.9)',
                boxShadow: 'none'
            }
        },
        '& > :first-child': {
            marginRight: 6
        }
    }
}));

function CategoryItem(props) {

    const {type, name, onClick, selected} = props;
    const classes = useStyles();

    return (
        <Typography variant="body1"
                    className={selected ? classes.menuItemSelected : classes.menuItem}
                    onClick={onClick}>
            {type === '게시판' ? <BulletinIcon className={classes.menuIcon}/> : <GalleryIcon className={classes.menuIcon}/>}
            {name}
        </Typography>
    )
}

export default function LeftMenu(props) {

    const {ds} = useStores();
    const location = useLocation();
    const classes = useStyles();
    const {openPage, categories, update, index} = props;

    let selectedArray = [];
    selectedArray.length = categories!==null ? categories.length + 2 : 2;
    const [array, setArray] = useState(null);   // menu index array to style the selected menu item

    const handleLogout = () => {
        storage.removeSession(SK.ACCESS_TOKEN);
        window.location.reload();
    }

    const handleSelect = (category, idx, type) => {
        selectedArray.fill(false);
        selectedArray[idx] = true;
        setArray(selectedArray);
        type === "board" ? openPage(category, idx, type) : openPage(null, idx, type);
    }

    useEffect(() => {
        if (categories!==null && categories.length > 0) {
            selectedArray.fill(false);
            selectedArray[index] = true;
            setArray(selectedArray)
        } else if (categories!==null && categories.length === 0 && location.state?.menuIdx === undefined) {
            setArray([true , false ]);
        }
    },[categories, index])

    useEffect(() => {
        if (categories!==null && categories.length > 0 && location.state?.menuIdx !== undefined) {
            selectedArray.fill(false);
            setArray(selectedArray)
        }
    },[update])

    return (
        <Box className={classes.leftMenu} display='flex' flexDirection='column'>
            <Box display='flex' justifyContent='center' className={classes.logoPng}>
                <Logo/>
            </Box>
            <Box display="flex" alignItems="center" className={classes.userInfo}>
                <AvatarIcon/>
                <Box display="flex" flexDirection="column" className={classes.profile}>
                    <Typography>{ds.user.name}</Typography>
                    <Typography onClick={handleLogout}>
                        로그아웃
                        <LogoutIcon/>
                    </Typography>
                </Box>
            </Box>
            {array!==null &&
                <Box className={classes.menuList}>
                    {categories!==null && categories.length > 0 &&
                        <React.Fragment>
                            <Typography variant="h5">Categories</Typography>

                            {categories.map((category, idx) => (
                                <CategoryItem key={idx}
                                              type={BoardType.getName(category.type)}
                                              name={category.name}
                                              selected={array[idx]}
                                              onClick={() => handleSelect(category, idx, "board")}
                                />
                            ))}
                        </React.Fragment>
                    }
                    <Typography variant="h5" sx={{marginTop: "25px"}}>Settings</Typography>
                    <Typography variant="body1"
                                className={array[array.length - 2] ? classes.menuItemSelected : classes.menuItem}
                                onClick={() => handleSelect(null, array.length - 2, "category")}>
                        <CategoryIcon className={classes.menuIcon}/>
                        카테고리 관리
                    </Typography>
                    <Typography variant="body1"
                                className={array[array.length-1] ? classes.menuItemSelected : classes.menuItem}
                                onClick={() => handleSelect(null, array.length-1, "code")}>
                        <CommonCodeIcon className={classes.menuIcon}/>
                        공통코드 관리
                    </Typography>
                </Box>
            }
            {
                categories!==null && categories.length < 5 &&
                <Box display="flex" alignItems='flex-end' className={classes.homepageBlock}>
                    <Box display="flex" justifyContent='center' className={classes.homepageBanner}>
                        <HomepageImage/>
                        <Box display="flex" alignItems="center" flexDirection='column'>
                            <Typography>게시물을 확인해보세요!</Typography>
                            <Typography>홈페이지 바로가기 버튼을 눌러주세요.</Typography>
                            <Box className={classes.homepageBtn}>
                                <Button variant='contained'
                                        onClick={() => window.open('https://fnsvalue.co.kr')}>국문 홈페이지</Button>
                                <Button variant='contained'
                                        onClick={() => window.open('https://fnsvalue.co.kr/?lang=en')}>영문 홈페이지</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            }
        </Box>
    )
}
class Storage {
    getSession = (key) => {
        try {
            return JSON.parse(sessionStorage.getItem(key));
        } catch (e) {
            return null;
        }
    };

    setSession = (key, val) => {
        sessionStorage.setItem(key, JSON.stringify(val));
    };

    removeSession = (key) => {
        sessionStorage.removeItem(key);
    };

    clearSession = () => {
        sessionStorage.clear();
    };

    getLocal = (key) => {
        try {
            return JSON.parse(localStorage.getItem(key));
        } catch (e) {
            return null;
        }
    };

    setLocal = (key, val) => {
        localStorage.setItem(key, JSON.stringify(val));
    };

    removeLocal = (key) => {
        localStorage.removeItem(key);
    };

    clearLocal = () => {
        localStorage.clear();
    };
};

export const StorageKey = {
    LOGIN_INFO: 'LoginInfo',
    ACCESS_TOKEN: 'AccessToken',
    REFRESH_TOKEN: 'RefreshToken',
    LOGIN_USER_INFO: 'LoginUserInfo',
    THEME: 'Theme'
};

export default new Storage();

import React, {useEffect, useState} from 'react';
import {Route, Switch, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import GalleryPage from "./GalleryPage";

export default function GalleryBoard(props) {

    const {category} = props;
    const history = useHistory();
    let {path, url} = useRouteMatch();
    const {state} = useLocation();
    const [menuId, setMenuId] = useState(state.menuIdx);

    function onPageChange() {
        history.push(`${url}/reorder`, {menuIdx: menuId})
    }

    useEffect(() => {
        setMenuId(state.menuIdx);
    }, []);

    return (
        <React.Fragment>
            <Switch>
                <Route exact path={path}
                       render={props => (<GalleryPage {...props} category={category} openPage={onPageChange}/>)}/>
            </Switch>
        </React.Fragment>
    );
}
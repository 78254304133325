import React, {useEffect, useState} from 'react';
import Header from "../../components/Header";
import {makeStyles} from "@mui/styles";
import {useLocation, useParams} from "react-router-dom";
import {Box, Tabs, Tab, Typography, Divider, Button, Switch} from "@mui/material";
import PropTypes from 'prop-types';
import LinkIcon from '@mui/icons-material/Link';
import ClearIcon from "@mui/icons-material/Clear";
import WarningDialog from "../../components/WarningDialog";
import api from "../../common/api";
import {CommonCodeValue, Language, VisibleType} from "../../common/types";
import {dataFormat} from "../../utils/FormatUtils";
import imgDoc from '../../images/icons/file_doc.png';
import imgPpt from '../../images/icons/file_ppt.png';
import imgPdf from '../../images/icons/file_pdf.png';
import imgHwp from '../../images/icons/file_hwp.png';
import imgUnknown from '../../images/icons/file_unknown.png';

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 1040,
        margin: '0 130px 50px 130px',
        '& .MuiTab-root': {
            width: 180,
            height: 52,
            backgroundColor: theme.palette.secondary.main,
            border: '1px solid #C8CACE',
            '&:after': {
                backgroundColor: theme.palette.secondary.main,
                borderBottom: '1px solid #C8CACE'
            }
        },
        '& .Mui-selected': {
            backgroundColor: '#fff',
            borderBottom: '1px solid #ffffff',
            zIndex: '1 !important',
            '&:after': {
                backgroundColor: '#fff !important',
                borderBottom: '1px solid #fff !important'
            }
        },
        '& .MuiTabs-indicator': {
            display: 'none'
        },
        '& > :first-child': {
            position: 'relative',
            zIndex: 10,
        },
        '& > :nth-child(2) , > :nth-child(3)': {
            position: 'relative',
            zIndex: 0,
            top: '-1px'
        }
    },
    tabs: {
        '& .MuiTabs-flexContainer': {
            '& > button': {
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
                zIndex: 0,
                overflow: 'visible',
                marginRight: 32,
                padding: '12px 10px 12px 20px',
                '&:after': {
                    position: 'absolute',
                    content: '""',
                    width: 44,
                    height: 52,
                    right: '-23px',
                    transform: 'skewX(20deg)',
                    borderRight: '1px solid #C8CACE',
                    borderTop: '1px solid #C8CACE',
                    borderTopRightRadius:  5
                },
            },
            '& > :nth-child(2)': {
                marginLeft: '-7px'
            }
        }
    },
    tabLabel: {
        display: 'flex',
        alignItems: 'center',
        '& > p': {
            color: theme.palette.text.gray,
            fontSize: 14,
            fontWeight: 800,
            fontFamily: ['sans-serif', 'Source Sans Pro', 'Nanum Gothic'],
            marginRight: 7
        },
        '& > span': {
            backgroundColor: '#9FA5B6',
            borderRadius: 15,
            width: 55,
            height: 21,
            fontSize: 11,
            fontWeight: 'bold',
            color: '#fff',
            padding: 4
        }
    },
    tabLabelOpen: {
        display: 'flex',
        alignItems: 'center',
        '& > p': {
            color: '#303030',
            fontSize: 14,
            fontWeight: 800,
            fontFamily: ['sans-serif', 'Source Sans Pro', 'Nanum Gothic'],
            marginRight: 7
        },
        '& > span': {
            backgroundColor: '#F48B8F',
            borderRadius: 15,
            width: 55,
            height: 21,
            fontSize: 11,
            fontWeight: 'bold',
            color: '#fff',
            padding: 4
        }
    },
    switchBtn: {
        width: 136,
        height: 26,
        padding: 0,
        marginBottom: 15,
        '& .Mui-checked': {
            transform: 'translateX(90%)',
            color: '#FFF'
        },
        '& .Mui-checked+.MuiSwitch-track': {
            backgroundColor: theme.palette.primary.main,
            opacity: 1
        },
        '& .MuiSwitch-thumb': {
            width: 65,
            height: 20,
            borderRadius: 36,
            padding: 0,
            boxShadow: 'none'
        },
        '& .MuiSwitch-track': {
            borderRadius: 36,
            padding: 3,
            backgroundColor: theme.palette.primary.main,
            opacity: 1,
            '&:before, &:after': {
                position: 'absolute',
                top: 6
            },
            '&:before': {
                left: 16,
                content: '"미게시"',
                color: theme.palette.text.white,
            },
            '&:after': {
                left: 87,
                width: 'fit-content',
                content: '"게시"',
                color: theme.palette.text.white
            },
        },
        '& .MuiSwitch-switchBase': {
            padding: 3,
            '&:before, &:after': {
                position: 'absolute',
            },
            '&:hover': {
                backgroundColor: 'transparent'
            },
            '& > input': {
                width: '300%',
                left: '-100%'
            }
        }
    },
    toggleChecked: {
        '& .MuiSwitch-switchBase': {
            '&:after': {
                left: 24,
                content: '"게시"',
                color: theme.palette.primary.main
            },
        }
    },
    toggleUnchecked: {
        '& .MuiSwitch-switchBase': {
            '&:before': {
                left: 16,
                content: '"미게시"',
                color: theme.palette.primary.main
            }
        }
    },
    panel: {
        backgroundColor: '#FFF',
        border: '1px solid #C8CACE',
        minHeight: 570,
        padding: '55px 90px  50px 90px',
        borderRadius: 5,
        borderTopLeftRadius: 0,
        marginBottom: 30,
        '& iframe': {
            marginBottom: 25
        }
    },
    title: {
        fontWeight: 700,
        fontSize: '1.5rem',
        color: theme.palette.text.dark,
        marginBottom: 12 ,
        lineHeight: '25px',
        wordBreak: 'break-word'
    },
    dataList: {
        minWidth: '100%',
        '& dl': {
            display: 'inline-flex',
            '& > dt': {
                fontWeight: 'bold',
                color: theme.palette.text.dark
            },
            '& > dd': {
                margin: '0 10px',
                color: theme.palette.text.gray
            }
        },
        '& > :first-child > :first-child': {
            marginRight: 35
        },
        '& > div > button': {
            justifySelf: 'flex-end',
            height: 27,
            width: 81,
            boxShadow: 'none',
            backgroundColor: '#fff',
            border: '1px solid #C8CACE',
            color: theme.palette.text.gray,
            fontSize: 12,
            fontWeight: 700,
            borderRadius: 2,
            padding: 6,
            marginLeft: 10,
            '& > svg': {
                marginRight: 2,
                width: 15,
                height: 15
            },
            '&:hover': {
                border: '1px solid #354A84',
                color: theme.palette.primary.main,
                backgroundColor: '#fff',
                boxShadow: 'none'
            }
        }
    },
    contentField: {
        lineHeight: '25px',
        color: theme.palette.text.dark,
        minHeight: 30,
        marginBottom: 20,
        whiteSpace: 'break-spaces',
        wordBreak: 'break-word'
    },
    urlField: {
        display: 'flex',
        marginBottom: 25,
        '& .MuiSvgIcon-root': {
            transform: 'rotate(-45deg)',
            width: 24,
            height: 24,
            color: theme.palette.primary.main,
            marginRight: 5,
            marginTop: '-4px'
        },
        '& > a': {
            textDecoration: 'none',
            '& > p': {
                color: '#1155CC',
                lineHeight: '18px',
                textDecoration: 'underline 1px solid #1155CC',
                textUnderlineOffset: '10%',
                wordBreak: 'break-all'
            }
        }
    },
    imgBox: {
        flexWrap: 'wrap',
        columnGap: 30,
        rowGap: 30,
        '& > img': {
            maxHeight: 280,
            maxWidth: 364
        }
    },
    buttons: {
        '& > button': {
            boxShadow: 'none',
            height: 50,
            width: 190,
            fontWeight: 'bold',
        },
        '& > :not(:last-child)': {
            marginRight: 10,
            border: '1px solid #9FA5B6',
            backgroundColor: '#fff',
            color: theme.palette.primary.main
        }
    },
    fileBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        rowGap: '10px',
        '& > a': {
            display: 'flex',
            textDecoration: 'none',
            color: '#555',
            fontWeight: 'bold',
            alignItems: 'center',
            columnGap: '5px'
        },
        '& > a > img.preview': {
            height:'20px'
        }
    }
}));

function isKorean(key) {
    return Language.getName(key) === "한국어"
}

function isEnglish(key) {
    return Language.getName(key) === "영어"
}

function isVisible(key) {
    return VisibleType.getName(key) === "게시"
}

function TabPanel(props) {

    const { children, value, index, post, categorySeq, boardSeq, handleTabDelete, ...other } = props;
    const classes = useStyles();

    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [postData, setPostData] = useState(null);

    const [url, setUrl] = useState(null);
    const [embedId, setEmbedId] = useState(null);

    const fetchData = () => {
        api.getBoardByLanguage(categorySeq, boardSeq, post.seq)
            .then((data) => {
                setPostData(data.data)
                setUrl(data.data.url)
            })
            .catch((err) => {
                console.log(CommonCodeValue.getErrMsg(err.rtCode))
            })
    }

    const onDelete = () => {
        handleTabDelete(index, post.seq, post.language);
    }

    useEffect(() => {
        fetchData()
    }, []);

    useEffect(() => {
        if (url !== null && url !== undefined && (url.includes("www.youtube.com") || url.includes("youtu.be"))) {
            let match = url.match(/.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/);
            match && match[1].length==11 ? setEmbedId(match[1]) : null;
        }
    }, [url]);


    return (
        <div role="tabpanel" hidden={value !== index} {...other} className={classes.panel}>
            {value === index && postData !== null &&
                <Box>
                    <Typography className={classes.title}>{postData.title}</Typography>
                    <Box display='flex' alignItems='center' className={classes.dataList}>
                        <Box flex={1}>
                            {postData.eventDate &&
                            <dl>
                                <dt>이벤트 날짜</dt>
                                <dd>{postData.eventDate}</dd>
                            </dl>
                            }
                            <dl>
                                <dt>작성자</dt>
                                <dd>{postData.regUserKey}</dd>
                            </dl>
                        </Box>

                        <Box flex={1} display='flex' justifyContent='flex-end' alignItems='center'>
                            <dl>
                                <dt>등록일</dt>
                                <dd>{dataFormat(postData.regDt, 'datetime')}</dd>
                            </dl>
                            <Button variant='contained'
                                    onClick={() => setOpenDeleteConfirm(true)}
                            ><ClearIcon/>삭제하기</Button>
                        </Box>

                    </Box>
                    <Divider light sx={{marginBottom: '15px'}}/>

                    <Box className={classes.fileBox}>
                        {postData.files && postData.files.length > 0 &&
                            postData.files.map((file) =>
                                <a key={file.seq} href={file.encodingSrc} alt={file.originalName} download={file.originalName}>
                                    <img className="preview" src={file.originalName.substring(file.originalName.lastIndexOf('.') + 1).match(/^(doc|docx)$/) ? imgDoc :
                                        (file.originalName.substring(file.originalName.lastIndexOf('.') + 1).match(/^(ppt|pptx)$/) ? imgPpt :
                                                (file.originalName.substring(file.originalName.lastIndexOf('.') + 1).match(/^(pdf)$/) ? imgPdf :
                                                        (file.originalName.substring(file.originalName.lastIndexOf('.') + 1).match(/^(hwp)$/) ? imgHwp :
                                                                imgUnknown
                                                        )
                                                )
                                        )} alt="file"/>
                                    {file.originalName}
                                </a>
                            )
                        }
                    </Box>
                    <Divider light sx={{marginBottom: '15px', marginTop: '15px'}}/>

                    <Box color='dark'>
                        {postData.content &&
                            <Typography variant='h4' className={classes.contentField} >
                                {postData.content}
                            </Typography>
                        }
                        {url !== null && url !== undefined &&
                            <Box className={classes.urlField}>
                                <LinkIcon/>
                                <a href={url} target="_blank">
                                    <Typography>{url}</Typography>
                                </a>
                            </Box>
                        }
                        {embedId !== null &&
                            <iframe
                                width="500"
                                height="280"
                                src={`https://www.youtube.com/embed/${embedId}`}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        }

                        <Box display='flex' className={classes.imgBox}>
                            {postData.images.length > 0 &&
                                postData.images.map((image) =>
                                    <img key={image.seq} src={image.encodingSrc} alt={image.originalName}/>
                                )
                            }
                        </Box>
                    </Box>
                </Box>
            }
            <WarningDialog open={openDeleteConfirm}
                           onOk={onDelete}
                           onClose={() => setOpenDeleteConfirm(false)}
                           title={"게시물 삭제 확인"}
                           content={"게시물 삭제하시겠습니까?"} />
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BoardDetailPage(props) {

    const classes = useStyles();
    const {state} = useLocation();
    const category = state.category;
    const {boardSeq} = useParams();
    const {openPage} = props;

    const [board, setBoard] = useState(null);
    const [koreanPost, setKoreanPost] = useState(null);
    const [englishPost, setEnglishPost] = useState(null);

    const [openDeleteAll, setOpenDeleteAll] = useState(false);
    const [openStatusConfirm, setOpenStatusConfirm] = useState(false);

    const [value, setValue] = useState(null);    // tab index value
    const [checked, setChecked] = useState(false);      // 그룹 게시 상태

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleSwitch = () => {
        setOpenStatusConfirm(true)
    }

    const onStatusChange = () => {
        let visible = isVisible(board.visible) ? VisibleType.notVisible : VisibleType.visible;
        api.updateBoardStatus(category.seq, boardSeq, visible)
            .then(() => {
                setChecked(!checked)
            })
            .catch((err) => {
                console.log(CommonCodeValue.getErrMsg(err.rtCode))
            })
        setOpenStatusConfirm(false)
    }

    const onDeleteGroup = () => {
        api.deleteBoardGroup(category.seq, boardSeq)
            .then(() => {
                openPage("list");
            })
            .catch((err) => {
                console.log(CommonCodeValue.getErrMsg(err.rtCode))
            })
        setOpenDeleteAll(false);
    }

    const onTabDelete = (index, seq, language) => {
        if (koreanPost && englishPost) {
            index === 0 ? setValue(1) : setValue(0);
            isKorean(language) ? setKoreanPost(undefined) : setEnglishPost(undefined)
            api.deleteBoardByLanguage(category.seq, boardSeq, seq)
                .then(() => {
                    getBoardData();
                })
                .catch((err) => {
                    console.log(CommonCodeValue.getErrMsg(err.rtCode))
                })
        } else if (englishPost === undefined || koreanPost === undefined) {
            api.deleteBoardByLanguage(category.seq, boardSeq, seq)
                .then(() => {
                    openPage("list");
                })
                .catch((err) => {
                    console.log(CommonCodeValue.getErrMsg(err.rtCode))
                })
        }
    }

    const getBoardData = () => {
        api.getBoardDetail(category.seq, boardSeq)
            .then((data) => {
                setBoard(data.data)
                setKoreanPost(data.data.boardList.find(board => isKorean(board.language)))
                setEnglishPost(data.data.boardList.find(board => isEnglish(board.language)))
                data.data.boardList.length > 1 ?
                    setValue(isKorean(state.board.boardList[0].language) ? 0 : 1) : setValue(isKorean(data.data.boardList[0].language) ? 0 : 1)
                setChecked(isVisible(data.data.visible))
            })
            .catch((err) => {
                console.log(CommonCodeValue.getErrMsg(err.rtCode))
                return null;
            })
    }

    function TabLabel({value, index, name}) {
        return (
            <Box className={value === index ? classes.tabLabelOpen : classes.tabLabel}>
                <Typography>{name}</Typography>
                <span>작성</span>
            </Box>
        )
    }

    useEffect(() => {
        getBoardData()
    }, []);

    return (
        <React.Fragment>
            <Header name={"게시물 상세"} navMain={category.name} navSecond={"게시물 상세"}
                    description={"FNSVALUE의 게시물을 확인 및 수정 할 수 있습니다."}/>
            {board!==null && value!==null &&
                <Box className={classes.root}>
                    <Box display='flex' alignItems='flex-end' justifyContent='space-between' zIndex={100}>
                        <Tabs value={value} onChange={handleTabChange} className={classes.tabs}>
                            <Tab label={<TabLabel value={value} index={0} name={'한국어'}/>}
                                 style={{display: koreanPost === undefined ? 'none' : 'default'}}
                                 disableRipple {...a11yProps(0)} />
                            <Tab label={<TabLabel value={value} index={1} name={'영어'}/>}
                                 style={{
                                     marginLeft: koreanPost === undefined ? '0px' : '-7px',
                                     display: englishPost === undefined ? 'none' : 'default'
                                 }}
                                 disableRipple {...a11yProps(1)} />
                        </Tabs>
                        <Switch color="primary"
                                checked={checked}
                                className={checked ? `${classes.switchBtn} ${classes.toggleChecked}` : `${classes.switchBtn} ${classes.toggleUnchecked}`}
                                onChange={handleSwitch}/>
                    </Box>

                    {koreanPost &&
                        <TabPanel value={value} index={0} post={koreanPost}
                                  categorySeq={category.seq} boardSeq={boardSeq}
                                  handleTabDelete={onTabDelete}/>}
                    {englishPost &&
                        <TabPanel value={value} index={1} post={englishPost}
                                  categorySeq={category.seq} boardSeq={boardSeq}
                                  handleTabDelete={onTabDelete}/>}

                    <Box display='flex' justifyContent='flex-end' className={classes.buttons}>
                        <Button onClick={() => openPage('list')}>목록보기</Button>
                        <Button onClick={() => setOpenDeleteAll(true)}>전체삭제</Button>
                        <Button variant='contained' onClick={() => openPage('edit', board)}>수정</Button>
                    </Box>
                </Box>
            }
            <WarningDialog open={openDeleteAll}
                           onClose={() => setOpenDeleteAll(false)}
                           onOk={onDeleteGroup}
                           title={'전체 삭제 확인'}
                           content={'게시물 전체 내용을 삭제하시겠습니까?'}/>
            <WarningDialog open={openStatusConfirm}
                           onClose={() => setOpenStatusConfirm(false)}
                           onOk={onStatusChange}
                           title={'게시물 상태 변경'}
                           content={'게시물 상태를 변경 하시겠습니까?'}/>
        </React.Fragment>
    )
}
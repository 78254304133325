import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#154782'
        },
        secondary: {
            light: '#F1F8FF',
            main: '#F9F9F9'
        },
        text: {
            primary: '#4E4E4E',
            secondary: '#454545',
            disabled: 'rgb(170, 170, 170)',
            hint: 'rgb(170, 170, 170)',
            divider: 'rgb(215, 215, 215)',
            title:'#141939',
            gray:'#666666',
            dark: '#333333',
            black: '#222',
            white:'#FFFFFF',
            red: '#F44336'
        },
        tag: {
            pink: "#F48B8F",
            blue: "#6E90F2",
            yellow: "#F8B849",
            grey: "#9FA5B6"
        }
    },
    typography: {
        fontFamily: ['Source Sans Pro', 'Nanum Gothic'],
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        h1: {
            fontFamily: ['sans-serif', 'Source Sans Pro', 'Nanum Gothic'],
            fontWeight: 800,
            fontSize: '1.875rem',   // 30px
            lineHeight: '1.875rem',
            letterSpacing: '0.00938em',
        },
        h2: {
            fontFamily: ['sans-serif', 'Source Sans Pro', 'Nanum Gothic'],
            fontWeight: 700,
            fontSize: '1rem',       // 16px
            lineHeight: '1rem',
            letterSpacing: '0.00938em',
        },
        h3: {
            fontFamily: ['sans-serif', 'Source Sans Pro', 'Nanum Gothic'],
            fontWeight: 700,
            fontSize: '0.938rem',   // 15px
            lineHeight: '0.928rem',
            letterSpacing: '0.00938em',
        },
        h4: {
            fontFamily: ['sans-serif', 'Source Sans Pro', 'Nanum Gothic'],
            fontWeight: 400,
            fontSize: '0.875rem',   // 14px
            lineHeight: '0.875rem',
            letterSpacing: '0.00938em',
        },
        h5: {
            fontFamily: ['Nanum Gothic', 'Source Sans Pro'],
            fontWeight: 600,
            fontSize: '0.813rem',   // 13px
            lineHeight: 1.167,
            letterSpacing: '0em'
        },
        h6: {
            fontFamily: ['Nanum Gothic', 'Source Sans Pro'],
            fontWeight: 700,
            fontSize: '0.688rem',   // 11px
            lineHeight: '10.89px',
            letterSpacing: '0em'
        },
        body1: {
            fontFamily: ['sans-serif', 'Source Sans Pro', 'Nanum Gothic'],
            fontWeight: 400,
            fontSize: '0.813rem',   // 13px
            lineHeight: '0.813rem',
            letterSpacing: '0.00938em',
        },
    },
    common: {
        calendarStyle: {
            backgroundColor: '#fff',
            border: '1px solid #154782',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            marginRight:'-50px',
            '& .MuiCalendarPicker-root': {
                '&>:first-child': {
                    marginTop: 30,
                    marginBottom: 15,
                    padding: '0 24px',
                    '& > :first-child': {
                        transform: 'translate(95px)'
                    }
                }
            },
            '& .PrivatePickersFadeTransitionGroup-root': {
                fontSize: 20,
                fontWeight: 800,
                color: '#154782'
            },
            '& .PrivatePickersSlideTransition-root': {
                minHeight: 240
            },
            '& .Mui-selected': {
                borderRadius: 5,
                color: '#FFF !important'
            },
            '& .MuiTypography-caption': {
                color: '#9FA5B6',
                fontWeight: 700
            },
            '& .MuiIconButton-root': {
                color: '#454545'
            },
            '& .MuiIconButton-root:first-of-type': {
                transform: 'translate(-187px)'
            },
            '& .MuiPickersDay-root': {
                color: '#666666',
                fontWeight: 800
            },
            '& .MuiPickersDay-root:hover': {
                borderRadius: 5
            },
            '& .MuiPickersDay-root:focus': {
                borderRadius: 5
            },
            '& .MuiPickersDay-dayOutsideMonth': {
                color: '#C9CDD6'
            }
        },
        dialogStyle: {
            dialog: {
                '& .MuiDialog-paper': {
                    minWidth: 440,
                    minHeight: 300,
                },
                '& .MuiDialogContent-root': {
                    padding: '0 30px 30px 30px'
                }
            },
            closeIcon: {
                flex: 1,
                display: 'flex',
                alignItems: 'flex-start',
                '& > .MuiIconButton-root': {
                    padding: 0,
                    '&:hover': {
                        backgroundColor: 'transparent'
                    }
                }
            },
            content: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                '& > :first-child': {
                    marginBottom: 30,
                    '& svg': {
                        margin: 0
                    }
                },
                '& > *': {
                    textAlign: 'center',
                }
            },
            title: {
                fontSize: '1.125rem',
                fontWeight: 700,
                color: '#141939',
                lineHeight: '18px',
                marginBottom: 10
            },
            message: {
                color: '#333333',
                marginBottom: 35,
                alignSelf: 'center',
                maxWidth: 300,
                lineHeight: '20px',
                whiteSpace: 'break-spaces'
            },
            buttons: {
                flex: 1,
                justifyContent: 'center',
                '& > button': {
                    width: 127,
                    height: 40,
                    borderRadius: 5,
                    boxShadow: 'none',
                    fontWeight: 'bold',
                    fontSize: 13,
                    color: '#fff'
                },
                '& > :first-child': {
                    backgroundColor: '#9FA5B6',
                    marginRight: 6
                },
                '& > :last-child': {
                    backgroundColor: '#154782'
                }
            }
        }
    },
    components: {
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(0,0,0,0.5)'
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#fff',
                    border: '1px solid #154782',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    borderRadius: 5
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: 0
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#fff',
                    color: '#666666',
                    borderRadius: 5,
                    border: '1px solid #9FA5B6',
                    boxShadow: '2px 2px 3px rgba(159, 165, 182, 0.4)',
                    fontWeight: 700,
                    fontSize: 13,
                    lineHeight: '20px',
                    padding: '25px 40px 20px 30px',
                    maxWidth: 360
                },
                arrow: {
                    "&:before": {
                        border: '1px solid #9FA5B6',
                        boxShadow: '2px 2px 3px rgba(159, 165, 182, 0.4)',
                    },
                    color: '#fff'
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fff',
                    color: '#454545',
                    '&:hover': {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#354A84'
                        }
                    }
                },
                notchedOutline: {
                    border: '1px solid #E7E8EB',
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor: 'transparent',
                    }
                }
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundColor: '#f9f9f9',
                    overflow: 'hidden',
                    scrollbarColor: 'rgba(184, 187, 190, 0.7) transparent', // firefox
                    scrollbarWidth: 'thin',                                 // firefox
                    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                        backgroundColor: "#F6F8FC",
                        width: "6px",
                        height: "6px"
                    },
                    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                        borderRadius: "100px",
                        backgroundColor: "#154782",
                        border: '1px solid transparent',
                        backgroundClip: 'content-box',
                    },
                    "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
                        backgroundColor: "#154782",
                    },
                    "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
                        backgroundColor: "#154782",
                    },
                    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "#154782",
                    },
                }
            }
        }
    }
});
import {makeStyles} from "@mui/styles";
import {TextField} from "@mui/material";
import {ReactComponent as SearchIcon} from "../images/icons/search_icon.svg";
import {useState} from "react";

const useStyles = makeStyles(() => ({
    searchBar: {
        maxWidth: 640,
        minWidth: 640,
        height: 50,
        marginRight: 10,
        '& .MuiOutlinedInput-root': {
            height: 50,
            paddingLeft: 20,
            backgroundColor: '#fff'
        },
        '& input': {
            padding: '13px 20px'
        }
    }
}));

export default function SearchBar(props) {
    const classes = useStyles();
    const {placeholder, onSearch} = props;
    const [keyword, setKeyword] = useState(null);

    const handleChange = (value) => {
        setKeyword(value.trim())
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            onSearch(keyword)
        }
    }

    return (
        <TextField className={classes.searchBar}
                   placeholder={placeholder}
                   onChange={e => handleChange(e.target.value)}
                   onKeyPress={handleKeyPress}
                   InputProps={{
                       startAdornment: <SearchIcon/>
                   }}
        />
    )

}


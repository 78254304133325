import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {useStores} from '../common/store';
import {Observer} from 'mobx-react';

export default function AuthRoute({component: Component, ...rest}) {
    const {ds} = useStores();

    return (<Observer>{() =>
        <Route
            {...rest}
            render={(props) => {
                if (ds.isLogin) {
                    return (
                        <React.Fragment>
                            <Component {...props}/>
                        </React.Fragment>
                    )
                } else {
                    return <Redirect to={{pathname: '/login', state: {from: props.location}}}/>;
                }
            }}
        />}
    </Observer>);
}

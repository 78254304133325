import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, IconButton, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Header from "../../components/Header";
import SearchBar from "../../components/SearchBar";
import api from "../../common/api";
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CreateDialog from "./CreateDialog";
import {dataFormat} from "../../utils/FormatUtils";
import EditDialog from "./EditDialog";
import Pagination from "../../components/Pagination";
import WarningDialog from "../../components/WarningDialog";
import {CommonCodeValue} from "../../common/types";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: 700,
        minWidth: 1000,
        margin: '0 130px',
    },
    createBtn: {
        boxShadow: 'none',
        height: 50,
        width: 190,
        fontWeight: 'bold'
    },
    emptyList: {
        backgroundColor: '#fff',
        borderRadius: 5,
        height: 60,
        width: '100%'
    },
    list: {
        marginTop: 30,
        display: 'flex',
        flexWrap: 'wrap',
        rowGap: 30,
        columnGap: 35,
        minHeight: 560
    },
    listItem: {
        width: 310,
        height: 307,
        backgroundColor: '#fff',
        borderRadius: 5,
        padding: '40px 35px 35px 35px',
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
            border: '1px solid #154782'
        }
    },
    clickableArea: {
        '& > :first-child': {
            fontWeight: 800,
            fontSize: '1.25rem',
            lineHeight: '19.8px',
            marginBottom: 8,
            color: '#333',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        },
        '&:hover': {
            cursor: 'pointer'
        }
    },
    codeName: {
        marginBottom: 30,
        '& > :first-child': {
            color: '#666',
            fontWeight: 800
        },
        '& > :last-child': {
            color: theme.palette.primary.main,
            fontWeight: 800
        },
    },
    codeDescription: {
        '& > :first-child': {
            color: '#666',
            fontWeight: 800,
            lineHeight: '13px',
            marginBottom: 6
        },
        '& > :last-child': {
            color: '#666',
            fontWeight: 400,
            fontSize: '0.875rem',
            lineHeight: '20px'
        },
    },
    itemBottom: {
        '& > :first-child': {
            color: '#666',
            fontWeight: 400,
            lineHeight: '13px',
            flex: 1
        },
        '& > :nth-child(2)': {
            marginRight: 6
        },
        '& > button': {
            width: 35,
            height: 35,
            color: theme.palette.primary.main,
            border: '1px solid #2E64A5',
            '& > svg': {
                width: 17,
                height: 17,
            },
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: '#fff'
            }
        },
    },
    pageBottom: {
        marginTop: 22,
        marginBottom: 60,
        alignItems: 'center',
        '& > *': {
            flex: 1
        },
        '& > :first-child > *': {
            fontSize: '0.875rem',
            color: '#9398A7'
        },
        '& > :first-child > :nth-child(2)': {
            color: theme.palette.primary.main,
            fontWeight: 'bold'
        },
        '& > :first-child > :last-child': {
            fontWeight: 'bold'
        },
        '& > :last-child': {
            display: 'flex',
            justifyContent: 'flex-end'
        }
    }
}));

export default function CodeGroupListPage(props) {

    const classes = useStyles();
    const {openCodePage} = props;
    const [codeGroupList, setCodeGroupList] = useState(null);
    const [totalPages, setTotalPages] = useState(0)
    const [totalPosts, setTotalPosts] = useState(0)
    const [openCreate, setOpenCreate] = useState(false);

    const keyword = useRef(null);
    const currentPage = useRef(0);

    const fetchData = () => {
        api.getCodeGroupList(keyword.current, currentPage.current)
            .then((data) => {
                setCodeGroupList(data.data.data);
                setTotalPages(data.data.page.totalPages);
                setTotalPosts(data.data.page.totalElements);
            })
            .catch((err) => {
                console.log(CommonCodeValue.getErrMsg(err.rtCode))
            })
    }

    const onSearch = (value) => {
        keyword.current = value;
        currentPage.current = 0;
        fetchData();
    }

    const onChangePage = (page) => {
        currentPage.current = page - 1;
        reload();
    }

    const onCloseCreate = () => {
        setOpenCreate(false);
    }

    const reload = (action) => {
        if (action==="delete" && codeGroupList.length===1) currentPage.current = currentPage.current - 1;
        fetchData();
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <React.Fragment>
            <Header name={"공통코드 관리"} navMain={"Settings"}  navSecond={"공통코드 관리"}
                    description={"FNSVALUE의 공통코드를 관리하는 페이지입니다."} {...props}/>
            <Box className={classes.root}>
                <Box display='flex' justifyContent='space-between'>
                    <SearchBar placeholder={"공통코드 내 검색"} onSearch={onSearch}/>
                    <Button variant="contained"
                            className={classes.createBtn}
                            onClick={() => setOpenCreate(true)}>+ 코드 그룹 추가</Button>
                </Box>
                {codeGroupList!==null &&
                    <CodeGroupList data={codeGroupList}
                                   reload={reload}
                                   openCodePage={openCodePage}/>
                }
                <Box display='flex' justifyContent='flex-end' className={classes.pageBottom}>
                    <Box display='flex'>
                        <Typography>Total:&nbsp;</Typography>
                        <Typography>{totalPosts}</Typography>
                        <Typography>&nbsp;posts</Typography>
                    </Box>
                    <Pagination totalPages={totalPages}
                                currentPage={currentPage.current + 1}
                                onChange={onChangePage}/>
                </Box>
            </Box>
            <CreateDialog open={openCreate}
                          createType={"codeGroup"}
                          title={"공통코드 그룹 추가"}
                          reload={reload}
                          onClose={onCloseCreate}/>
        </React.Fragment>
    )
}

function CodeGroupList({openCodePage, data, reload}) {

    const classes = useStyles();

    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [deleteSeq, setDeleteSeq] = useState(null);

    const onOpenEdit  = (item) => {
        setEditItem(item)
        setOpenEdit(true);
    }

    const onCloseEdit  = () => {
        setOpenEdit(false);
        reload()
    }

    const onDelete = (seq) => {
        setDeleteSeq(seq);
        setOpenDeleteConfirm(true);
    }

    const onCloseDelete = () => {
        setOpenDeleteConfirm(false)
    }

    const onOkDelete = (seq) => {
        api.deleteCodeGroup(seq)
            .then(() => {
                reload('delete');
            })
            .catch((err) => {
                console.log(CommonCodeValue.getErrMsg(err.rtCode))
            });
        onCloseDelete();
    }

    return (
        <Box className={classes.list}>
            {data !== null &&
                data.map((codeGroup) => (
                    <Box key={codeGroup.code} className={classes.listItem} display='flex' flexDirection='column'>
                        <Box className={classes.clickableArea}
                             display='flex' flexDirection='column' flex={1} onClick={() => openCodePage(1, codeGroup)}>
                            <Typography>{codeGroup.name}</Typography>
                                <Box className={classes.codeName} display='flex' alignItems='center'>
                                    <Typography>그룹코드 :&nbsp;</Typography>
                                    <Typography>{codeGroup.code}</Typography>
                                </Box>
                            <Box className={classes.codeDescription} display='flex' flexDirection='column'>
                                <Typography>그룹 설명</Typography>
                                <Typography>{codeGroup.description}</Typography>
                            </Box>
                        </Box>
                        <Box display='flex' alignItems='flex-end' className={classes.itemBottom}>
                            <Typography>{dataFormat(codeGroup.regDt, "date")}</Typography>
                            <IconButton onClick={() => onOpenEdit(codeGroup)}><EditIcon/></IconButton>
                            <IconButton onClick={() => onDelete(codeGroup.seq)}><DeleteOutlineIcon/></IconButton>
                        </Box>
                    </Box>
                ))
            }
            {data.length === 0 &&
                <Box display='flex' alignItems='center' justifyContent='center' className={classes.emptyList}>
                    <Typography>조회할 공통코드 그룹이 없습니다</Typography>
                </Box>
            }
            <WarningDialog open={openDeleteConfirm}
                           onOk={() => onOkDelete(deleteSeq)}
                           onClose={onCloseDelete}
                           title={"공통코드 그룹 삭제"}
                           content={"포함되어 있는 모든 공통코드가 삭제됩니다. 공통코드 그룹을 삭제하시겠습니까?"}
            />
            <EditDialog open={openEdit}
                        onClose={onCloseEdit}
                        editItem={editItem}
                        reload={reload}
                        editType={"codeGroup"}
                        title={"공통코드 그룹 수정"}
            />
        </Box>
    )
}
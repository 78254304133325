import React from 'react';
import {makeObservable, action, observable} from 'mobx';
import {MobXProviderContext} from 'mobx-react';
import {theme} from './theme';

class Store {

    constructor() {
        makeObservable(this)
    }

    @observable
    isLogin = false;

    @observable
    token = null;

    @observable
    user = null;

    theme = theme;

    @action
    setLogin = (isLogin) => {
        this.isLogin = isLogin;
    };

    @action
    setToken = (token) => {
        this.token = token;
    };

    @action
    setUser = (user) => {
        this.user = user;
    };

    @action
    getUserKey = () => {
        return this.user.userKey;
    }

    @action
    getUserType = () => {
        return this.user.userType;
    }
}

export default new Store();

export function useStores() {
    return React.useContext(MobXProviderContext);
}

import React, {useEffect, useState} from 'react';
import {Route, Switch, useLocation, useRouteMatch} from "react-router-dom";
import {useHistory} from 'react-router';
import ReorderPage from "../../components/ReorderPage";
import CategoryListPage from "./CategoryListPage";

export default function CategorySettings(props) {

    const history = useHistory();
    let {path, url} = useRouteMatch();
    const {state} = useLocation();
    const [menuId, setMenuId] = useState(state.menuIdx);
    const {updateMenu} = props;

    function onPageChange() {
        history.push(`${url}/reorder`, {menuIdx: menuId, type: "category"})
    }

    useEffect(() => {
        setMenuId(state.menuIdx);
    }, []);

    return (
        <React.Fragment>
            <Switch>
                <Route exact path={path}
                       render={props => (<CategoryListPage {...props}
                                                           openPage={onPageChange}
                                                           updateMenuList={updateMenu}/>)}/>
                <Route exact path={`${path}/reorder`}
                       render={props => (<ReorderPage target={"category"}
                                                      headerTitle={"카테고리 관리"}
                                                      headerDescription={"카테고리의 순서를 변경할 수 있는 페이지입니다."}
                                                      searchPlaceholder={"카테고리 내 검색"}
                                                      updateMenuList={updateMenu}
                                                      {...props}/>)}>
                </Route>
            </Switch>
        </React.Fragment>
    );
}
import React, {useEffect, useRef, useState} from "react";
import Header from "../../components/Header";
import {Box, Button, IconButton, Tooltip, Typography} from "@mui/material";
import SearchBar from "../../components/SearchBar";
import CreateDialog from "./CreateDialog";
import {makeStyles} from "@mui/styles";
import api from "../../common/api";
import {useLocation} from "react-router-dom";
import {dataFormat} from "../../utils/FormatUtils";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditDialog from "./EditDialog";
import Pagination from "../../components/Pagination";
import {ReactComponent as InfoIcon} from "../../images/icons/info_icon.svg";
import {ReactComponent as ReorderIcon} from "../../images/icons/reorder_icon.svg";
import WarningDialog from "../../components/WarningDialog";
import {CommonCodeValue} from "../../common/types";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: 700,
        margin: '0 130px',
        '& > :first-child > :first-child': {
            flex: 1
        }
    },
    createBtn: {
        boxShadow: 'none',
        height: 50,
        width: 190,
        fontWeight: 'bold'
    },
    reorderBtn: {
        boxShadow: 'none',
        border: '1px solid #9FA5B6',
        height: 50,
        width: 190,
        fontWeight: 'bold',
        marginRight: 10,
        backgroundColor: '#fff',
        color: theme.palette.primary.main,
        '& > svg': {
            margin: '1px 10px 0 0  ',
        },
        '&[disabled] > svg > path': {
            fill: '#9FA5B6'
        }
    },
    detailContent: {
        backgroundColor: '#fff',
        borderRadius: 5,
        marginTop: 30,
        padding: 40,
        '& > :first-child': {
            color: theme.palette.text.title,
            fontSize: '1.5rem',
            fontWeight: 700,
            lineHeight: '1.5rem',
            marginBottom: 10,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        }
    },
    tooltip: {
        marginLeft: 10
    },
    tooltipContent: {
        '& > :first-child': {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: '20px',
            marginBottom: 5
        },
        '& > :last-child': {
            color: theme.palette.text.gray,
            fontWeight: 700,
            fontSize: 13,
            lineHeight: '20px',
            wordBreak: 'keep-all'
        }
    },
    list: {
        marginTop: 40,
        '& > *': {
            marginBottom: 10
        }
    },
    listEmpty: {
        height: 85,
        backgroundColor: '#fff',
        borderRadius: 5,
        border: '1px solid #E7E8EB',
        padding: '20px 30px',
        marginTop: 40
    },
    listItem: {
        height: 85,
        backgroundColor: '#fff',
        borderRadius: 5,
        border: '1px solid #E7E8EB',
        padding: '20px 30px',
        '& > :first-child': {
            flex: 7,
            minWidth: 400
        },
        '& > :not(:first-child)': {
            flex: 1,
            maxWidth: 150,
            display:'flex',
            justifyContent: 'flex-end'
        },
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
            border: '1px solid #154782'
        }
    },
    codeDetail: {
        '& > :first-child': {
            marginBottom: 7,
            alignItems: 'flex-end',
            maxWidth: '80%',
            '& > :first-child': {
                color: theme.palette.text.dark,
                fontWeight: 'bold',
                fontSize: '1.125rem',
                lineHeight: "18px",
                marginRight: 10,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                wordBreak: 'keep-all',
                whiteSpace: 'nowrap'
            },
            '& > :last-child': {
                color: theme.palette.primary.main,
                fontWeight: 800
            },
        },
        '& > :last-child': {
            color: theme.palette.text.secondary,
            fontWeight: 400,
            fontSize: '0.875rem',
            lineHeight: '20px'
        },
    },
    codeDate: {
        maxWidth: 130,
        '& > :first-child': {
            color: theme.palette.text.dark,
            fontWeight: 'bold',
            marginBottom: 8
        },
        '& > :last-child': {
            color: theme.palette.text.secondary,
        },
    },
    buttons: {
        '& > :first-child': {
            marginRight: 6
        },
        '& > button': {
            width: 35,
            height: 35,
            color: theme.palette.primary.main,
            border: '1px solid #9FA5B6',
            '& > svg': {
                width: 17,
                height: 17,
            },
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: '#fff'
            }
        }
    },
    pageBottom: {
        marginTop: 13,
        alignItems: 'center',
        justifyContent: 'flex-end'
    }
}));

export default function CodeListPage(props) {

    const classes = useStyles();
    const {state} = useLocation();
    let codeGroup = state.codeGroup;
    const {openCodePage} = props;

    const [codeList, setCodeList] = useState(null);
    const [totalPages, setTotalPages] = useState(0)
    const [openCreate, setOpenCreate] = useState(false);

    const keyword = useRef(null);
    const currentPage = useRef(0);

    const fetchData = () => {
        api.getCodeList(codeGroup.seq, keyword.current, currentPage.current)
            .then((data) => {
                setCodeList(data.data.data);
                setTotalPages(data.data.page.totalPages);
            })
            .catch((err) => {
                console.log(CommonCodeValue.getErrMsg(err.rtCode))
            })
    }

    const onSearch = (value) => {
        keyword.current = value;
        currentPage.current = 0;
        reload();
    }

    const onChangePage = (page) => {
        currentPage.current = page - 1;
        reload();
    }

    const onCloseCreate = () => {
        setOpenCreate(false);
    }

    const reload = () => {
        fetchData();
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <React.Fragment>
            <Header name={"공통코드 관리"} navMain={"공통코드 관리"} navSecond={"그룹 상세"} description={"FNSVALUE의 공통코드를 관리하는 페이지입니다."}/>
            <Box className={classes.root}>
                <Box display='flex'>
                    <SearchBar placeholder={"공통코드 내 검색"} onSearch={onSearch}/>
                    <Box display='flex' justifyContent='flex-end' flex={1}>
                        <Button className={classes.reorderBtn} onClick={() => openCodePage(2, codeGroup)}>
                            <ReorderIcon/> 코드 순서 변경
                        </Button>
                        <Button variant="contained"
                                className={classes.createBtn}
                                onClick={() => setOpenCreate(true)}>+ 코드 추가</Button>
                    </Box>
                </Box>
                <Box display='flex' flexDirection='column'
                     className={classes.detailContent}
                     style={totalPages > 1 ? {paddingBottom: '27px'} : {paddingBottom: '80px'}}>
                    <Typography>
                        {codeGroup.name}
                        <Tooltip title={(
                                        <Box className={classes.tooltipContent}>
                                            <Typography color='primary'>그룹 설명</Typography>
                                            <Typography>{codeGroup.description}</Typography>
                                        </Box>
                                    )}
                                 placement="right-start"
                                 className={classes.tooltip} arrow>
                            <InfoIcon/>
                        </Tooltip>
                    </Typography>
                    <Box display='flex'>
                        <Typography variant='h2'>그룹 코드 :&nbsp;</Typography>
                        <Typography variant='h2' color='primary'>{codeGroup.code}</Typography>
                    </Box>
                    {codeList !== null && codeList.length > 0 ?
                        <CodeList data={codeList} reload={reload}/>
                        :
                        <Box key={'no-code'} className={classes.listEmpty} display='flex' alignItems='center' justifyContent='center'>
                            <Typography>공통 코드가 없습니다</Typography>
                        </Box>
                    }
                    {totalPages > 1 &&
                        <Box display='flex' justifyContent='flex-end' className={classes.pageBottom}>
                            <Pagination totalPages={totalPages}
                                        currentPage={currentPage.current + 1}
                                        onChange={onChangePage}/>
                        </Box>
                    }
                </Box>
            </Box>
            <CreateDialog open={openCreate}
                          createType={"code"}
                          title={"공통코드 추가"}
                          reload={reload}
                          onClose={onCloseCreate}
                          groupSeq={codeGroup.seq}
                          groupCode={codeGroup.code}/>
        </React.Fragment>
    )
}

function CodeList({data, reload}) {

    const classes = useStyles();
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [deleteSeq, setDeleteSeq] = useState(null);

    const onOpenEdit  = (item) => {
        setEditItem(item)
        setOpenEdit(true);
    }

    const onCloseEdit  = () => {
        setOpenEdit(false);
    }

    const onDelete = (seq) => {
        setDeleteSeq(seq)
        setOpenDeleteConfirm(true);
    }

    const onCloseDelete = () => {
        setOpenDeleteConfirm(false)
    }

    const onOkDelete = (seq) => {
        api.deleteCode(seq)
            .then(() => {
                reload();
            })
            .catch((err) => {
                console.log(CommonCodeValue.getErrMsg(err.rtCode))
            });
        onCloseDelete();
    }

    return (
        <Box className={classes.list}>
            {data !== null &&
                data.map((item) => (
                    <Box key={item.code} className={classes.listItem} display='flex' alignItems='center'>
                        <Box className={classes.codeDetail} display='flex' flexDirection='column'>
                            <Box display='flex'>
                                <Typography>{item.name}</Typography>
                                <Typography>{item.code}</Typography>
                            </Box>
                            <Typography>{item.description}</Typography>
                        </Box>
                        <Box  className={classes.codeDate}  display='flex' flexDirection='column'>
                            <Typography>등록일</Typography>
                            <Typography>{dataFormat(item.regDt, "date")}</Typography>
                        </Box>
                        <Box className={classes.buttons}>
                            <IconButton onClick={() => onOpenEdit(item)}><EditIcon/></IconButton>
                            <IconButton onClick={() => onDelete(item.seq)}><DeleteOutlineIcon/></IconButton>
                        </Box>
                    </Box>
                ))
            }
            <WarningDialog open={openDeleteConfirm}
                           onOk={() => onOkDelete(deleteSeq)}
                           onClose={onCloseDelete}
                           title={"공통코드 삭제"}
                           content={"공통코드를 삭제하시겠습니까?"}
            />
            <EditDialog open={openEdit}
                        onClose={onCloseEdit}
                        editItem={editItem}
                        editType={"code"}
                        reload={reload}
                        title={"공통코드 수정"}
            />
        </Box>
    )
}
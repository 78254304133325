import React, {useEffect, useRef, useState} from "react";
import api from "../../common/api";
import {BoardType, CommonCodeValue, ConditionType, OptionType, UseType} from "../../common/types";
import Header from "../../components/Header";
import {Box, Button, FormGroup, TextField, Typography} from "@mui/material";
import SearchBar from "../../components/SearchBar";
import {ReactComponent as ReorderIcon} from "../../images/icons/reorder_icon.svg";
import {ReactComponent as BulletinIcon} from "../../images/icons/bulletin_icon.svg";
import {ReactComponent as GalleryIcon} from "../../images/icons/gallery_icon.svg";
import CustomCheckBox from "../../components/CustomCheckBox";
import {dataFormat} from "../../utils/FormatUtils";
import ClearIcon from "@mui/icons-material/Clear";
import CategoryCreateDialog from "./CategoryCreateDialog";
import WarningDialog from "../../components/WarningDialog";
import {createStyles, makeStyles} from "@mui/styles";
import _ from "lodash";
import ConfirmDialog from "../../components/ConfirmDialog";

const useStyles = makeStyles((theme) => createStyles({
    root: {
        margin: '0 130px 50px 130px'
    },
    createBtn: {
        boxShadow: 'none',
        height: 50,
        width: 190,
        fontWeight: 'bold'
    },
    reorderBtn: {
        boxShadow: 'none',
        border: '1px solid #9FA5B6',
        height: 50,
        width: 190,
        fontWeight: 'bold',
        marginRight: 10,
        backgroundColor: '#fff',
        color: theme.palette.primary.main,
        '& > svg': {
            margin: '1px 10px 0 0  ',
        }
    },
    pageTop: {
        marginBottom: 30
    },
    emptyList: {
        height: 60,
        borderRadius: 5,
        backgroundColor: '#fff',
        '& > *': {
            color: theme.palette.text.secondary,
            fontSize: '0.813rem'
        }
    },
    categoryBox: {
        backgroundColor: '#fff',
        borderRadius: 5,
        padding: '30px 30px 30px 40px',
        minHeight: 215,
        minWidth: 1000,
        marginBottom: 10,
        '& > svg': {
            width: 25,
            height: 25,
            marginTop: 8,
            '& > path': {
                fill: theme.palette.primary.main
            }
        },
    },
    categoryFields: {
        padding: '0 32px',
        marginRight: 28,
        minWidth: 300,
        width: 450,
        '& > *': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderRadius: 5
            }
        },
        '& > :first-child': {
            marginBottom: 10,
            '& .MuiOutlinedInput-root': {
                height: 40,
                fontWeight: 800,
                fontSize: 14,
                '& > ::placeholder': {
                    color: theme.palette.text.red,
                    opacity:1
                },
                '&:hover > ::placeholder': {
                    opacity: 0.4,
                    color: theme.palette.text.gray
                }
            }
        },
        '& > :last-child': {
            '& .MuiOutlinedInput-root': {
                height: 95,
                alignItems: 'flex-start',
                fontSize: 14
            }
        }
    },
    tooltipContent: {
        '& > :first-child': {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: '20px',
            marginBottom: 5
        },
        '& > :last-child': {
            color: theme.palette.text.gray,
            fontWeight: 700,
            fontSize: 13,
            lineHeight: '20px',
            wordBreak: 'keep-all'
        }
    },
    categoryOptions: {
        minWidth: 415,
        borderRight: '1px solid #E7E8EB',
        '& > *': {
            alignItems: 'center',
            height: 35,
            '& > .MuiTypography-root': {
                color: theme.palette.text.secondary,
                fontWeight: 'bold',
                minWidth: 75
            },
            '& > :nth-child(2)': {
                display: 'flex',
                flexDirection: 'row',
                marginLeft: 50,
                '& > :first-child': {
                    marginRight: 40
                },
                '& .MuiTypography-root': {
                    fontWeight: 'bold'
                }
            }
        }
    },
    categoryInfo: {
        padding: '26px 30px 0 95px',
        '& > :first-child': {
            marginBottom: 10
        },
        '& > :not(:last-child)': {
            '& > :first-child': {       // label
                fontWeight: 800,
                color: theme.palette.primary.main
            },
            '& > :last-child': {        // data
                color: theme.palette.text.secondary
            },
        },
        '& > button': {
            marginTop: 30,
            height: 36,
            width: 133,
            boxShadow: 'none',
            backgroundColor: '#fff',
            border: '1px solid #C8CACE',
            color: theme.palette.text.gray,
            fontSize: 12,
            fontWeight: 700,
            borderRadius: 2,
            '& > svg': {
                marginRight: 5,
                width: 15,
                height: 15
            },
            '&:hover': {
                border: '1px solid #354A84',
                color: theme.palette.primary.main,
                backgroundColor: '#fff',
                boxShadow: 'none'
            }
        }
    },
    pageBottom: {
        marginTop: 30,
        '& > :first-child': {
            boxShadow: 'none',
            border: '1px solid #9FA5B6',
            height: 50,
            width: 190,
            fontWeight: 'bold',
            marginRight: 10,
            backgroundColor: '#fff',
            color: theme.palette.primary.main
        },
        '& > :last-child': {
            boxShadow: 'none',
            height: 50,
            width: 190,
            fontWeight: 'bold'
        }
    }
}));

const isUsed = (category, type) => {
    if (category.optionList.length > 0) {
        let key = category.optionList.filter((opt) => opt.optionType === type)[0]?.useStatus;
        if (key === undefined) return false;
        return key === UseType.used
    } else return false;
}

const isRequired = (category, type) => {
    if (category.optionList.length > 0) {
        let key = category.optionList.filter((opt) => opt.optionType === type)[0]?.required;
        if (key === undefined) return false;
        return key === ConditionType.required
    } else return false;
}

const isAllCreated = (category, type) => {
    if (category.optionList.length > 0) {
        let allCreated = category.optionList.filter((opt) => opt.optionType === type)[0]?.isAllCreated;
        if (allCreated === undefined) return false;
        return allCreated;
    } else return false;
}

const isGallery = (category) => {
    return category.type === BoardType.gallery;
}

export default function CategoryListPage(props) {

    const classes = useStyles();
    const {openPage, updateMenuList} = props;

    const [categories, setCategories] = useState(null); // 실제 데이터
    const [categoriesUpt, setCategoriesUpt] = useState(null);   // 수정한 데이터
    const categoriesClone = useRef([]);     // 수정 취소 (storing original data)
    const updatedCategories = useRef([]);   // 화면에서 보이는 카테고리만 저장

    const [deleteSeq, setDeleteSeq] = useState(null);
    const [openCreate, setOpenCreate] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openCancelConfirm, setOpenCancelConfirm] = useState(false);
    const [openSaveConfirm, setOpenSaveConfirm] = useState(false);
    const [openNoChange, setOpenNoChange] = useState(false);

    const keyword = useRef(null);
    const [checkedArray, setCheckedArray] = useState([]);
    const [errors, setErrors] = useState([]);

    const fetchData = () => {
        api.getCategoryList()
            .then((data) => {
                data.data.map((item) => {
                    item.optionList.map((option) => {
                        if (option.required === undefined) {
                            option.required = ConditionType.optional
                        }
                    })
                })
                setCategories(data.data);
                setCategoriesUpt(_.cloneDeep(data.data));
                categoriesClone.current = _.cloneDeep(data.data)
                setCheckedArray(Array.from({length: data.data.length}, (elm, idx) => {
                    elm = {
                        seq: data.data[idx].seq,
                        content: {
                            used: isUsed(data.data[idx], OptionType.content),
                            required: isRequired(data.data[idx], OptionType.content),
                            isAllCreated: isAllCreated(data.data[idx], OptionType.content)
                        },
                        image: {
                            used: isUsed(data.data[idx], OptionType.image),
                            required: isRequired(data.data[idx], OptionType.image),
                            isAllCreated: isAllCreated(data.data[idx], OptionType.image)
                        },
                        URL: {
                            used: isUsed(data.data[idx], OptionType.URL),
                            required: isRequired(data.data[idx], OptionType.URL),
                            isAllCreated: isAllCreated(data.data[idx], OptionType.URL)
                        },
                        eventDate: {
                            used: isUsed(data.data[idx], OptionType.eventDate),
                            required: isRequired(data.data[idx], OptionType.eventDate),
                            isAllCreated: isAllCreated(data.data[idx], OptionType.eventDate)
                        },
                        file: {
                            used: isUsed(data.data[idx], OptionType.file),
                            required: isRequired(data.data[idx], OptionType.file),
                            isAllCreated: isAllCreated(data.data[idx], OptionType.file)
                        },
                    };
                    return elm;
                }))
                setErrors(Array.from({length: data.data.length}, (elm, idx) => {
                    elm = {
                        seq: data.data[idx].seq,
                        error: false
                    };
                    return elm;
                }));
            })
            .catch((err) => {
                console.log(CommonCodeValue.getErrMsg(err.rtCode))
            })
    }

    const getIdxBySeq = (seq) => {
        return categoriesUpt.findIndex((item) => item.seq === seq)
    }

    const searchData = () => {
        api.getCategoryList(keyword.current)
            .then((data) => {
                setCategories(data.data);
            })
            .catch((err) => {
                console.log(CommonCodeValue.getErrMsg(err.rtCode))
            })
    }

    const updateOriginalData = () => {
        api.getCategoryList()
            .then((data) => {
                data.data.map((item) => {
                    item.optionList.map((option) => {
                        if (option.required === undefined) option.required = ConditionType.optional
                    })
                })
                categoriesClone.current = _.cloneDeep(data.data)
            })
            .catch((err) => {
                console.log(CommonCodeValue.getErrMsg(err.rtCode))
            })
    }

    const onSearch = (value) => {
        keyword.current = value;
        reload();
    }

    const onCancelEdit = () => {
        setCategories(null);
        setCategoriesUpt(null);
        setCheckedArray([]);
        reload();
        setOpenCancelConfirm(false);
    }

    const onOpenCreate = () => {
        setOpenCreate(true)
    }

    const onCloseCreate = (result) => {
        setCategories(null);
        setCategoriesUpt(null);
        setCheckedArray([]);
        reload();
        if (result==="success") updateMenuList("added");
        setOpenCreate(false);
    }

    const reload = () => {
        if (keyword.current === null) {
            fetchData();
        } else {
            searchData()
        }
    }

    const onOpenDelete = (seq) => {
        setDeleteSeq(seq);
        setOpenDelete(true);
    }

    const onChangeText = (category, field, value) => {
        let idx = getIdxBySeq(category.seq)
        if (field === "name") {
            categoriesUpt[idx].name = value;
            let errIdx = errors.findIndex(item => item.seq === category.seq);
            if (errors[errIdx].error && value.trim()!=='') {
                let errorsClone = Array.from(errors);
                errorsClone[errIdx].error = false;
                setErrors(errorsClone);
            }
        } else if (field === "description") {
            categoriesUpt[idx].description = value
        }
    }

    const handleCheckOption = (value, name, optionType, seq) => {

        let idx = checkedArray.findIndex((item) => item.seq === seq);
        let catIdx = getIdxBySeq(seq);

        let tempArray = Array.from(checkedArray);
        if (name === "used") {
            tempArray[idx][optionType].used = value;
            if (!value) tempArray[idx][optionType].required = value;
            setCheckedArray(tempArray)
        } else if (name === "required") {
            tempArray[idx][optionType].required = value;
            if (value) tempArray[idx][optionType].used = value;
            setCheckedArray(tempArray)
        }

        let optionIdx = categoriesUpt[catIdx].optionList.findIndex((opt) => opt.optionType === OptionType[optionType])
        categoriesUpt[catIdx].optionList[optionIdx].useStatus = checkedArray[idx][optionType].used ? UseType.used : UseType.notUsed;
        categoriesUpt[catIdx].optionList[optionIdx].required = checkedArray[idx][optionType].required ? ConditionType.required : ConditionType.optional;
    }

    const checkForErrors = () => {

        categoriesUpt.map((category) => {
            if (categories.find((item) => item.seq === category.seq) !== undefined && category.name==='') {
                let idx = errors.findIndex(item => item.seq === category.seq);
                let errorsClone = Array.from(errors);
                errorsClone[idx].error = true;
                setErrors(errorsClone);
            }
        })

        if (errors.filter(e => e.error===true).length === 0) {
            updatedCategories.current = [];
            categoriesUpt.map((category) => {
                categoriesClone.current.map((original) => {
                    if (original.seq === category.seq &&
                        (!_.isEqual(category.name, original.name) || !_.isEqual(category.description, original.description) || !_.isEqual(category.optionList, original.optionList))) {
                        if (categories.find((item) => item.seq === category.seq) !== undefined) {
                            updatedCategories.current.push(category)
                        }
                    }
                })
            })
            if (updatedCategories.current.length > 0) {
                setOpenSaveConfirm(true);
            } else {
                setOpenNoChange(true)
            }
        }
    }

    const onSaveChanges = () => {
        api.updateCategory(updatedCategories.current)
            .then(() => {
                if (keyword!==null) updateOriginalData();
                updateMenuList();
                reload();
            })
            .catch((err) => {
                console.log(CommonCodeValue.getErrMsg(err.rtCode))
            })
        setOpenSaveConfirm(false)
    }

    const onDeleteCategory = () => {
        api.deleteCategory(deleteSeq)
            .then(() => {
                updateMenuList("deleted");
                reload();
            })
            .catch((err) => {
                console.log(CommonCodeValue.getErrMsg(err.rtCode))
            })
        setOpenDelete(false);
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <React.Fragment>
            <Header name={"카테고리 관리"} navMain={"Settings"} navSecond={"카테고리 관리"}
                    description={"FNSVALUE의 카테고리를 추가 또는 수정할 수 있는 페이지입니다."} {...props}/>
            <Box className={classes.root}>
                <Box display='flex' justifyContent='space-between' className={classes.pageTop}>
                    <SearchBar placeholder={"카테고리 내 검색"} onSearch={onSearch}/>
                    <Box display='flex' justifyContent='flex-end' flex={1}>
                        <Button className={classes.reorderBtn} onClick={openPage}>
                            <ReorderIcon/> 카테고리 순서 변경
                        </Button>
                        <Button variant="contained"
                                className={classes.createBtn}
                                onClick={onOpenCreate}>+ 카테고리 추가</Button>
                    </Box>
                </Box>
                {categories !== null &&
                    categories.map((category, idx) => (
                        <Box key={category.seq} display='flex' className={classes.categoryBox}>
                            { isGallery(category) ? <GalleryIcon/> : <BulletinIcon/> }
                            <Box display='flex' flexDirection='column' className={classes.categoryFields}>
                                <TextField defaultValue={categoriesUpt ? categoriesUpt[getIdxBySeq(category.seq)].name : category.name}
                                           inputProps={{maxLength: 30}}
                                           error={errors[errors.findIndex(i => i.seq===category.seq)]?.error}
                                           placeholder={errors[errors.findIndex(i => i.seq===category.seq)]?.error ? "필수 항목값을 입력해주세요" : undefined}
                                           onChange={(e) => onChangeText(category, "name", e.target.value)}
                                />
                                <TextField defaultValue={categoriesUpt ? categoriesUpt[getIdxBySeq(category.seq)].description : category.description}
                                           multiline
                                           inputProps={{maxLength: 50}}
                                           onChange={(e) => onChangeText(category, "description", e.target.value)}
                                />
                            </Box>

                            {checkedArray.length > 0 &&
                                <Box display='flex' flexDirection='column' className={classes.categoryOptions}>
                                    <Box display='flex'>
                                        <Typography>본문</Typography>
                                        <FormGroup>
                                            <CustomCheckBox label='사용'
                                                            name="used"
                                                            disabled={isGallery(category)}
                                                            tooltipTitle={'카테고리 사용 항목'}
                                                            tooltipContent={'미사용으로 변경하실 경우 해당 카테고리에서 모든 본문 데이터는 삭제됩니다.'}
                                                            showTooltip={isUsed(categoriesClone.current[idx], OptionType.content)}
                                                            checked={checkedArray[idx].content.used}
                                                            onChange={(value, name) => handleCheckOption(value, name, "content", category.seq)}
                                            />
                                            <CustomCheckBox label='필수'
                                                            name="required"
                                                            tooltipTitle={'카테고리 필수 항목'}
                                                            tooltipContent={'해당 옵션이 모든 게시물에 작성된 경우에만 필수 설정이 가능합니다.'}
                                                            showTooltip={checkedArray[idx].content.used && !checkedArray[idx].content.isAllCreated && !isGallery(category)}
                                                            checked={checkedArray[idx].content.required}
                                                            disabled={!checkedArray[idx].content.isAllCreated || isGallery(category)}
                                                            onChange={(value, name) => handleCheckOption(value, name, "content", category.seq)}
                                            />
                                        </FormGroup>
                                    </Box>
                                    <Box display='flex'>
                                        <Typography>이미지</Typography>
                                        <FormGroup>
                                            <CustomCheckBox label='사용'
                                                            name="used"
                                                            disabled={isGallery(category)}
                                                            tooltipTitle={'카테고리 사용 항목'}
                                                            tooltipContent={'미사용으로 변경하실 경우 해당 카테고리에서 모든 이미지 데이터는 삭제됩니다.'}
                                                            showTooltip={isUsed(categoriesClone.current[idx], OptionType.image)}
                                                            checked={isGallery(category) ? true : checkedArray[idx].image.used}
                                                            onChange={(value, name) => handleCheckOption(value, name, "image", category.seq)}
                                            />
                                            <CustomCheckBox label='필수'
                                                            name="required"
                                                            disabled={!checkedArray[idx].image.isAllCreated || isGallery(category)}
                                                            tooltipTitle={'카테고리 필수 항목'}
                                                            tooltipContent={'해당 옵션이 모든 게시물에 작성된 경우에만 필수 설정이 가능합니다.'}
                                                            showTooltip={checkedArray[idx].image.used && !checkedArray[idx].image.isAllCreated && !isGallery(category)}
                                                            onChange={(value, name) => handleCheckOption(value, name, "image", category.seq)}
                                                            checked={isGallery(category) ? true : checkedArray[idx].image.required} />
                                        </FormGroup>
                                    </Box>
                                    <Box display='flex'>
                                        <Typography>URL</Typography>
                                        <FormGroup>
                                            <CustomCheckBox label='사용'
                                                            name="used"
                                                            disabled={isGallery(category)}
                                                            tooltipTitle={'카테고리 사용 항목'}
                                                            tooltipContent={'미사용으로 변경하실 경우 해당 카테고리에서 모든 URL 데이터는 삭제됩니다.'}
                                                            showTooltip={isUsed(categoriesClone.current[idx], OptionType.URL)}
                                                            checked={checkedArray[idx].URL.used}
                                                            onChange={(value, name) => handleCheckOption(value, name, "URL", category.seq)}
                                            />
                                            <CustomCheckBox label='필수'
                                                            name="required"
                                                            disabled={!checkedArray[idx].URL.isAllCreated || isGallery(category)}
                                                            tooltipTitle={'카테고리 필수 항목'}
                                                            tooltipContent={'해당 옵션이 모든 게시물에 작성된 경우에만 필수 설정이 가능합니다.'}
                                                            showTooltip={checkedArray[idx].URL.used && !checkedArray[idx].URL.isAllCreated && !isGallery(category)}
                                                            onChange={(value, name) => handleCheckOption(value, name, "URL", category.seq)}
                                                            checked={checkedArray[idx].URL.required}/>
                                        </FormGroup>
                                    </Box>
                                    <Box display='flex'>
                                        <Typography>이벤트 날짜</Typography>
                                        <FormGroup>
                                            <CustomCheckBox label='사용'
                                                            name="used"
                                                            disabled={isGallery(category)}
                                                            tooltipTitle={'카테고리 사용 항목'}
                                                            tooltipContent={'미사용으로 변경하실 경우 해당 카테고리에서 모든 이벤트 날짜 데이터는 삭제됩니다.'}
                                                            showTooltip={isUsed(categoriesClone.current[idx], OptionType.eventDate)}
                                                            checked={checkedArray[idx].eventDate.used}
                                                            onChange={(value, name) => handleCheckOption(value, name, "eventDate", category.seq)}
                                            />
                                            <CustomCheckBox label='필수'
                                                            name="required"
                                                            disabled={!checkedArray[idx].eventDate.isAllCreated || isGallery(category)}
                                                            tooltipTitle={'카테고리 필수 항목'}
                                                            tooltipContent={'해당 옵션이 모든 게시물에 작성된 경우에만 필수 설정이 가능합니다.'}
                                                            showTooltip={!checkedArray[idx].eventDate.isAllCreated && checkedArray[idx].eventDate.used && !isGallery(category)}
                                                            checked={checkedArray[idx].eventDate.required}
                                                            onChange={(value, name) => handleCheckOption(value, name, "eventDate", category.seq)}
                                            />
                                        </FormGroup>
                                    </Box>
                                    <Box display='flex'>
                                        <Typography>파일</Typography>
                                        <FormGroup>
                                            <CustomCheckBox label='사용'
                                                            name="used"
                                                            disabled={isGallery(category)}
                                                            tooltipTitle={'카테고리 사용 항목'}
                                                            tooltipContent={'미사용으로 변경하실 경우 해당 카테고리에서 모든 파일 데이터는 삭제됩니다.'}
                                                            showTooltip={isUsed(categoriesClone.current[idx], OptionType.file)}
                                                            checked={checkedArray[idx].file.used}
                                                            onChange={(value, name) => handleCheckOption(value, name, "file", category.seq)}
                                            />
                                            <CustomCheckBox label='필수'
                                                            name="required"
                                                            disabled={!checkedArray[idx].file.isAllCreated || isGallery(category)}
                                                            tooltipTitle={'카테고리 필수 항목'}
                                                            tooltipContent={'해당 옵션이 모든 게시물에 작성된 경우에만 필수 설정이 가능합니다.'}
                                                            showTooltip={!checkedArray[idx].file.isAllCreated && checkedArray[idx].file.used && !isGallery(category)}
                                                            checked={checkedArray[idx].file.required}
                                                            onChange={(value, name) => handleCheckOption(value, name, "file", category.seq)}
                                            />
                                        </FormGroup>
                                    </Box>
                                </Box>
                            }

                            <Box display='flex' flexDirection='column' className={classes.categoryInfo}>
                                <Box display='flex'>
                                    <Typography sx={{marginRight: '10px'}}>수정일</Typography>
                                    <Typography>{dataFormat(category.uptDt, 'date')}</Typography>
                                </Box>
                                <Box display='flex'>
                                    <Typography  sx={{marginRight: '10px'}}>수정자</Typography>
                                    <Typography>{category.uptUserKey}</Typography>
                                </Box>
                                <Button variant='contained' onClick={() => onOpenDelete(category.seq)}><ClearIcon/>삭제하기</Button>
                            </Box>

                        </Box>
                    ))
                }
                {categories !== null && categories.length > 0 &&
                    <Box display='flex' justifyContent='flex-end' className={classes.pageBottom}>
                        <Button variant="contained" onClick={() => setOpenCancelConfirm(true)}>취소</Button>
                        <Button variant="contained" onClick={checkForErrors}>저장</Button>
                    </Box>
                }
                {(categories === null || categories.length === 0) &&
                    <Box key={'none'} display='flex' alignItems='center' justifyContent='center' className={classes.emptyList}>
                        <Typography id="title">조회할 카테고리가 없습니다</Typography>
                    </Box>
                }
            </Box>

            <CategoryCreateDialog open={openCreate}
                                  onClose={onCloseCreate}/>
            <WarningDialog open={openCancelConfirm}
                           onClose={() => setOpenCancelConfirm(false)}
                           onOk={onCancelEdit}
                           title={'카테고리 수정 취소'}
                           content={'수정한 카테고리는 초기화됩니다.\n 취소하시겠습니까?'}
            />
            <WarningDialog open={openDelete}
                           onClose={() => setOpenDelete(false)}
                           onOk={onDeleteCategory}
                           title={'카테고리 삭제'}
                           content={'카테고리를 삭제하시겠습니까?'}
            />
            <ConfirmDialog open={openSaveConfirm}
                           onClose={() => setOpenSaveConfirm(false)}
                           onOk={onSaveChanges}
                           title={'카테고리 저장'}
                           content={'카테고리 설정을 저장하시겠습니까?'}
            />
            <WarningDialog open={openNoChange}
                           onClose={() => setOpenNoChange(false)}
                           onOk={() => setOpenNoChange(false)}
                           title={'카테고리 변경'}
                           content={'변경된 내용이 없습니다'}
            />
        </React.Fragment>
    )
}
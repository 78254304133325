import {makeStyles} from "@mui/styles";
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle, IconButton,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import api from "../../common/api";
import {ReactComponent as CodeIcon} from "../../images/icons/common_code_icon.svg";
import CloseIcon from "@mui/icons-material/Close";
import MobxReactForm from "mobx-react-form";
import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";
import {CommonCodeValue} from "../../common/types";

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialogContent-root': {
            padding: '25px 30px 30px 30px',
        }
    },
    title: {
        display: 'flex',
        minHeight: 70,
        padding: '10px 10px 5px 30px',
        '& p': {
            color: theme.palette.text.title,
            fontWeight: 700,
            fontSize: '1.125rem',
            alignSelf: 'flex-end',
            marginBottom: 7
        }
    },
    icon: {
        height: 35,
        width: 35,
        background: theme.palette.primary.main,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'flex-end',
        marginRight: 10,
        '& > svg': {
            width: 15,
            height: 15,
        }
    },
    closeIcon: {
        flex: 1,
        display: 'flex',
        alignItems: 'flex-start',
        '& > .MuiIconButton-root': {
            padding: 0,
            width: 36,
            height: 36,
            '&:hover': {
                backgroundColor: theme.palette.secondary.light
            }
        }
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: 400,
        minHeight: 250
    },
    field: {
        minHeight: 40,
        marginBottom: 10,
        '& > :first-child': {
            marginTop: 12,
            color: theme.palette.text.dark,
            fontWeight: 'bold',
            minWidth: 77
        },
        '& .MuiOutlinedInput-root': {
            maxHeight: 40,
            minWidth: 320,
            fontSize: 12
        },
        '& .Mui-disabled': {
            '-webkit-text-fill-color': '#1155CC',
            fontWeight: 'bold',
            fontSize: 12,
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#E7E8EB'
            },
            '&:hover': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#E7E8EB'
                }
            }
        }
    },
    descriptionField: {
        marginBottom: 25,
        '& > :first-child': {
            marginTop: 12,
            color: theme.palette.text.dark,
            fontWeight: 'bold',
            minWidth: 77
        },
        '& .MuiOutlinedInput-root': {
            minHeight: 88,
            minWidth: 320,
            fontSize: 12,
            alignItems: 'flex-start'
        },
    },
    buttons: {
        justifyContent: 'flex-end',
        '& > *': {
            width: 127,
            height: 40,
            borderRadius: 5,
            boxShadow: 'none',
            fontWeight: 'bold',
            fontSize: 13,
            color: '#fff'
        },
        '& > :first-child': {
            backgroundColor: '#9FA5B6',
            marginRight: 6
        },
        '& > :last-child': {
            backgroundColor: theme.palette.primary.main
        }
    }
}));

export default function EditDialog({editType, editItem, open, title, onClose, reload}) {

    const classes = useStyles();
    const [name, setName] = useState(editItem?.name);
    const [code, setCode] = useState(editItem?.code);
    const [description, setDescription] = useState(editItem?.description);
    const [errors, setErrors] = useState(null);

    const isCodeGroup = editType === "codeGroup";
    const isCode = editType === "code";

    const form = new MobxReactForm({
        fields: [
            { name: 'name', value: name, rules: 'required' },
            { name: 'code', value: code },
            { name: 'description', value: description}
        ]
    }, {
        plugins: {
            dvr: dvr(validatorjs)
        },
        hooks: {
            onSubmit() {
                setErrors(null);
            },
            onError(form) {
                setErrors(form.errors());
            },
            onSuccess() {
                if (isCodeGroup) {
                    api.updateCodeGroup(editItem.seq, name, description)
                        .then(() => {
                            reload();
                        })
                        .catch((err) => {
                            console.log(CommonCodeValue.getErrMsg(err.rtCode))
                        });
                } else if (isCode) {
                    api.updateCode(editItem.seq, name, description)
                        .then(() => {
                            reload();
                        })
                        .catch((err) => {
                            console.log(CommonCodeValue.getErrMsg(err.rtCode))
                        });
                }
                onClose();
            }
        }
    });

    useEffect(() => {
        setName(editItem?.name)
        setCode(editItem?.code)
        setDescription(editItem?.description)
        setErrors(null);
    }, [open]);

    return (
        <Dialog open={open} onClose={onClose} className={classes.dialog}>
            <DialogTitle className={classes.title}>
                <Box className={classes.icon}><CodeIcon/></Box>
                <Typography>{title}</Typography>
                <Box flex={1} justifyContent='flex-end' className={classes.closeIcon}>
                    <IconButton aria-label="close" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent >
                <form id="edit-form" className={classes.content} noValidate autoComplete="off" onSubmit={form.onSubmit}>
                    <Box display='flex' className={classes.field}>
                        <Typography>{isCodeGroup ? "그룹 이름" : "코드 이름"}</Typography>
                        <TextField id={'name'}
                                   defaultValue={form.$('name').value}
                                   required
                                   inputProps={{maxLength : 100}}
                                   error={errors === null ? false : errors?.['name'] !== null}
                                   placeholder={errors === null ? null : errors?.['name'] !== null ? "필수 항목값을 입력해주세요" : null}
                                   onChange={e => setName(e.target.value)}/>
                    </Box>
                    <Box display='flex' className={classes.field}>
                        <Typography>{isCodeGroup ? "그룹 코드" : "공통 코드"}</Typography>
                        <TextField id={'code'}
                                   defaultValue={code}
                                   disabled/>
                    </Box>
                    <Box display='flex' className={classes.descriptionField}>
                        <Typography>{isCodeGroup ? "그룹 설명" : "코드 설명"}</Typography>
                        <TextField defaultValue={description??''}
                                   required={false}
                                   inputProps={{maxLength: 50}}
                                   multiline
                                   onChange={e => setDescription(e.target.value)}/>
                    </Box>
                    <Box display='flex' className={classes.buttons}>
                        <Button onClick={onClose} variant="contained" color="secondary">취소</Button>
                        <Button type='submit' variant="contained" onClick={form.onSubmit}>수정</Button>
                    </Box>
                </form>
            </DialogContent>
        </Dialog>
    )
}
import React from "react";
import {Box, Typography} from "@mui/material";
import {makeStyles, createStyles} from "@mui/styles";
import {ReactComponent as HomeIcon} from "../images/icons/home_icon.svg";
import {ReactComponent as ArrowRightIcon} from "../images/icons/arrow_right.svg";

const useStyles = makeStyles((theme) => createStyles({
    topBar: {
        display: 'flex',
        width: '100%'
    },
    pageTitle: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minWidth: 'fit-content',
        padding: '80px 130px 43px 130px',
        '& > :nth-child(2)': {
            marginTop: 20
        }
    },
    title: {
        color: theme.palette.text.title
    },
    pageNav: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        alignSelf: 'flex-start',
        padding: '60px 134px 0 0',
        minWidth: 'fit-content',
        '& > *': {
            fontSize: '0.75rem',
            fontWeight: 400,
            marginLeft: 10
        }
    }
}));

export default function Header(props) {

    const {name, navMain, navSecond, description} = props;
    const classes = useStyles();

    return (
        <Box className={classes.topBar}>
            <Box className={classes.pageTitle}>
                <Typography variant="h1" className={classes.title}>{name}</Typography>
                <Typography variant="h3">{description}</Typography>
            </Box>
            <Box className={classes.pageNav}>
                <HomeIcon/>
                <Typography variant="h3">{navMain}</Typography>
                {navSecond !== undefined &&
                    <React.Fragment>
                        <ArrowRightIcon/>
                        <Typography variant="h3">{navSecond}</Typography>
                    </React.Fragment>
                }
            </Box>
        </Box>
    )
}
import {makeStyles} from "@mui/styles";
import {Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography} from "@mui/material";
import {ReactComponent as WarningIcon} from "../images/icons/warning_icon.svg";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

const useStyles = makeStyles((theme) => ({
    ...theme.common.dialogStyle
}));

export default function WarningDialog({open, title, content, onOk, onClose}) {

    const classes = useStyles();

    return (
        <Dialog open={open} onClose={onClose} className={classes.dialog}>
            <DialogTitle>
                <Box flex={1} justifyContent='flex-end' className={classes.closeIcon}>
                    <IconButton aria-label="close" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Box display='flex' justifyContent='center'>
                    <WarningIcon/>
                </Box>

                <Typography className={classes.title}>{title}</Typography>
                <Typography variant='h4' className={classes.message}>{content}</Typography>

                <Box display='flex' className={classes.buttons}>
                    <Button onClick={onClose} variant="contained" color="secondary">취소</Button>
                    <Button onClick={onOk} variant="contained">확인</Button>
                </Box>
            </DialogContent>
        </Dialog>
    )

}
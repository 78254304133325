import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from "mobx-react";
import store from './common/store';
import {ThemeProvider, CssBaseline} from "@mui/material";
import { StyledEngineProvider } from '@mui/material/styles';

ReactDOM.render(
    <React.Fragment>
        <Provider ds={store}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={store.theme}>
                    <CssBaseline/>
                    <App/>
                </ThemeProvider>
            </StyledEngineProvider>
        </Provider>
    </React.Fragment>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.unregister();

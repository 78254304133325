import React, {useEffect, useState} from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {useStores} from "./common/store";
import api from "./common/api";
import LoginPage from "./pages/LoginPage";
import AuthRoute from "./utils/AuthRoute";
import LayoutPage from "./pages/LayoutPage";
import {Observer} from 'mobx-react';

function App() {
    const {ds} = useStores();
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (api.hasToken()) {
            api.getMe()
                .then(() => {
                    ds.setLogin(true);
                })
                .catch(() => {
                    ds.setLogin(false);
                })
                .finally(() => {
                    setChecked(true);
                });
        } else {
            setChecked(true);
        }
    }, []);

    return (
        <Observer>{() => {
            return checked ?
                <div>
                    <Router>
                        <Switch>
                            <Route path='/login' component={LoginPage}/>
                            <AuthRoute path='/' component={LayoutPage}/>
                        </Switch>
                    </Router>
                </div>
                : null }}
        </Observer>);
}

export default App;

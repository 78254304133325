import React, {useState} from 'react';
import {ReactComponent as CheckedIcon} from "../images/icons/checked_icon.svg";
import {ReactComponent as UncheckedIcon} from "../images/icons/unchecked_icon.svg";
import {Box, Checkbox, FormControlLabel, Tooltip, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    unchecked: {
        '& .MuiCheckbox-root.Mui-disabled': {
            '& svg': {
                '& > g > :first-child': {
                    fill: theme.palette.secondary.main
                },
                '& > g > :nth-child(2)': {
                    stroke: '#9FA5B6'
                },
                '& > path': {
                    fill: '#9FA5B6 !important',
                    stroke: '#9FA5B6'
                }
            }
        },
        '& .MuiFormControlLabel-label.Mui-disabled': {
            color: '#9FA5B6'
        },
        '& .MuiTypography-root': {
            color: theme.palette.text.dark,
        }
    },
    checked: {
        '& .MuiCheckbox-root.Mui-disabled': {
            '& svg': {
                '& > g > :first-child': {
                    fill: theme.palette.secondary.main
                },
                '& > g > :nth-child(2)': {
                    stroke: '#9FA5B6'
                },
                '& > path': {
                    fill: '#9FA5B6 !important',
                    stroke: '#9FA5B6'
                }
            }
        },
        '& .MuiFormControlLabel-label.Mui-disabled': {
            color: '#9FA5B6'
        },
        '& .MuiTypography-root': {
            color: theme.palette.primary.main
        }
    },
    tooltipContent: {
        '& > :first-child': {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: '20px',
            marginBottom: 5
        },
        '& > :last-child': {
            color: theme.palette.text.gray,
            fontWeight: 700,
            fontSize: 13,
            lineHeight: '20px',
            wordBreak: 'keep-all'
        }
    }
}));

export default function CustomCheckBox(props) {

    const {
        defaultChecked,
        disabled,
        label,
        value,
        onChange,
        required,
        name,
        checked,
        tooltipTitle,
        tooltipContent,
        showTooltip = false
    } = props;

    const classes = useStyles();

    const [check, setCheck] = useState(false);

    const handleChange = (event) => {
        setCheck(event.target.checked);
        onChange !== undefined && onChange(event.target.checked, event.target.name);
    };

    return (
        <Tooltip title={(
                    <Box className={classes.tooltipContent}>
                        <Typography color='primary'>{tooltipTitle}</Typography>
                        <Typography>{tooltipContent}</Typography>
                    </Box>
                )}
                 disableHoverListener={!showTooltip}
                 placement="right-start" arrow>
            <div>
                <FormControlLabel control={
                                    <Checkbox defaultChecked={defaultChecked}
                                              checkedIcon={<CheckedIcon/>}
                                              icon={<UncheckedIcon/>}
                                              disabled={disabled}
                                              value={value}
                                              required={required}
                                              checked={checked ?? check}
                                              onChange={handleChange}
                                              name={name}
                                    />}
                                  label={label}
                                  className={(checked ?? check) ? classes.checked : classes.unchecked}
                />
            </div>
        </Tooltip>
    )
}
import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from "@mui/styles";
import Header from "../../components/Header";
import {Box, Button, Typography} from "@mui/material";
import SearchBar from "../../components/SearchBar";
import Pagination from "../../components/Pagination";
import {ReactComponent as ReorderIcon} from "../../images/icons/reorder_icon.svg";
import {ReactComponent as CheckMarkIcon} from "../../images/icons/check_mark_icon.svg";
import {ReactComponent as SortIcon} from "../../images/icons/sort_icon.svg";
import {ReactComponent as SortDownIcon} from "../../images/icons/sort_down.svg";
import {ReactComponent as SortUpIcon} from "../../images/icons/sort_up.svg";
import api from "../../common/api";
import {CommonCodeValue, Language, SortType, VisibleType} from "../../common/types";
import {useStores} from "../../common/store";
import {dataFormat} from "../../utils/FormatUtils";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 1040,
        margin: '0 130px 50px 130px',
    },
    createBtn: {
        boxShadow: 'none',
        height: 50,
        width: 190,
        fontWeight: 'bold'
    },
    reorderBtn: {
        boxShadow: 'none',
        border: '1px solid #9FA5B6',
        height: 50,
        width: 190,
        fontWeight: 'bold',
        marginRight: 10,
        backgroundColor: '#fff',
        color: theme.palette.primary.main,
        '& > svg': {
            margin: '1px 10px 0 0  ',
        },
        '&[disabled] > svg > path': {
            fill: '#9FA5B6'
        }
    },
    pageTop: {
        marginBottom: 30
    },
    tableHead: {
        height: 50,
        backgroundColor: '#fff',
        borderRadius: 5,
        marginBottom: 15,
        '& > *': {
            color: theme.palette.text.dark,
            fontWeight: 'bold',
            fontSize: '0.875rem',
            flex: 1,
            paddingLeft: 30,
            display: 'flex',
            height: '100%',
            alignItems: 'center'
        },
        '& #status': {
            maxWidth: 130
        },
        '& #title': {
            paddingLeft: 0
        },
        '& #language': {
            maxWidth: 130,
        },
        '& #reg-user': {
            maxWidth: 250,
        },
        '& #edit-date': {
            maxWidth: 140,
        },
        '& #reg-date': {
            maxWidth: 140
        },
        '& #status, #edit-date, #reg-date': {
            "&:hover": {
                cursor: 'pointer'
            },
            '& > svg': {
                marginLeft: 7
            }
        }
    },
    listItemEmpty: {
        height: 60,
        borderRadius: 5,
        backgroundColor: '#fff',
        '& > *': {
            color: theme.palette.text.secondary,
            fontSize: '0.813rem'
        }
    },
    listItem: {
        height: 60,
        borderRadius: 5,
        backgroundColor: '#fff',
        minWidth: 0,
        '&:hover': {
            border: '1px solid #154782',
            backgroundColor: theme.palette.secondary.light,
            cursor: 'pointer'
        },
        '& > *': {
            color: theme.palette.text.secondary,
            fontSize: '0.813rem',
            flex: 1,
            paddingLeft: 30
        },
        '& #status': {
            maxWidth: 130,
            '& > :first-child': {
                width: 70,
                height: 25,
                borderRadius: 41,
                border: '1px solid #9FA5B6',
                fontSize: 11,
                fontWeight: 'bold',
                '& > svg': {
                    marginRight: 5
                }
            }
        },
        '& #title': {
            paddingLeft: 0,
            fontWeight: 'bold',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        },
        '& #language': {
            maxWidth: 130,
            display: 'flex',
            '& > :not(:last-child)': {
                marginRight: 5
            }
        },
        '& #reg-user': {
            maxWidth: 250,
        },
        '& #edit-date': {
            maxWidth: 140,
        },
        '& #reg-date': {
            maxWidth: 140
        }
    },

    pageBottom: {
        marginTop: 22,
        alignItems: 'center',
        '& > *': {
            flex: 1
        },
        '& > :first-child > *': {
            fontSize: '0.875rem',
            color: '#9398A7'
        },
        '& > :first-child > :nth-child(2)': {
            color: theme.palette.primary.main,
            fontWeight: 'bold'
        },
        '& > :first-child > :last-child': {
            fontWeight: 'bold'
        },
        '& > :last-child': {
            display: 'flex',
            justifyContent: 'flex-end'
        }
    }
}));

const tagStyles = makeStyles(() => ({
    languageTag: {
        width: 20,
        height: 20,
        borderRadius: 5,
        color: '#fff',
        fontSize: 12,
        fontWeight: 'bold'
    }
}));

function LanguageTags({boardList}) {

    const classes = tagStyles();
    const {ds} = useStores();

    const isKorean = (key) => {
        return Language.getName(key) === "한국어"
    }

    const isEnglish = (key) => {
        return Language.getName(key) === "영어"
    }

    return (
        <React.Fragment>
            {boardList.map((board, idx) => (
                idx < 2 && (isKorean(board.language) || isEnglish(board.language)) &&
                <Box key={board.seq} display='flex' justifyContent='center' alignItems='center'
                     style={isKorean(board.language) ? {backgroundColor: ds.theme.palette.tag.pink} : {backgroundColor: ds.theme.palette.tag.blue}}
                     className={classes.languageTag}>
                    {Language.getName(board.language).charAt(0)}
                </Box>
            ))}
        </React.Fragment>
    )
}

export default function BoardListPage(props) {

    const classes = useStyles();
    const {category, openPage} = props;

    const [postList, setPostList] = useState([]);

    const [totalPages, setTotalPages] = useState(1)
    const [totalPosts, setTotalPosts] = useState(2)

    const keyword = useRef(null);
    const currentPage = useRef(0);
    const sortUptDt = useRef(null);
    const sortRegDt = useRef(null);
    const sortStatus = useRef(null);
    const [update, setUpdate] = useState(false);

    const fetchData = () => {
        api.getBoardGroupList(
            category.seq,
            keyword.current,
            sortStatus.current,
            sortUptDt.current,
            sortRegDt.current,
            currentPage.current, 7)
            .then((data) => {
                setPostList(data.data)
                setTotalPosts(data.page.totalElements)
                setTotalPages(data.page.totalPages)
                if (update) setUpdate(false)
            })
            .catch((err) => {
                console.log(CommonCodeValue.getErrMsg(err.rtCode))
            })
    }

    const onSearch = (value) => {
        keyword.current = value;
        fetchData();
        reload();
    }

    const handleSort = (type) => {
        if (type==="status") {
            sortStatus.current === null ? sortStatus.current = VisibleType.visible :
                sortStatus.current === VisibleType.visible ? sortStatus.current = VisibleType.notVisible :
                    sortStatus.current = null;
            fetchData();
        } else if (type==="uptDt") {
            sortUptDt.current === null ? sortUptDt.current = SortType.sortByAsc :
                sortUptDt.current === SortType.sortByAsc ? sortUptDt.current = SortType.sortByDesc :
                    sortUptDt.current = null;
            fetchData();
        } else if (type==="regDt") {
            sortRegDt.current === null ? sortRegDt.current = SortType.sortByAsc :
                sortRegDt.current === SortType.sortByAsc ? sortRegDt.current = SortType.sortByDesc :
                    sortRegDt.current = null;
            fetchData();
        }
    }
    
    const reload = () => {
        setUpdate(true)
    }

    const onChangePage = (page) => {
        currentPage.current = page-1;
        fetchData()
        reload();
    }

    useEffect(() => {
        fetchData()
    }, [update]);

    return (
        <React.Fragment>
            <Header name={category?.name} navMain={category?.name} navSecond={category?.name + " 게시물"} description={category?.description} {...props}/>
            <Box className={classes.root}>
                <Box display='flex' justifyContent='space-between' className={classes.pageTop}>
                    <SearchBar placeholder={category?.name + " 내 검색"} onSearch={onSearch}/>
                    <Box display='flex' justifyContent='flex-end' flex={1}>
                        <Button className={classes.reorderBtn} onClick={openPage}>
                            <ReorderIcon/> 게시물 순서 변경
                        </Button>
                        <Button variant="contained"
                                className={classes.createBtn}
                                onClick={() => openPage("create")}>+ 게시물 등록</Button>
                    </Box>
                </Box>
                
                <Box display='flex' alignItems='center' className={classes.tableHead}>
                    <Typography id="status" onClick={() => handleSort("status")}>
                        상태 {sortStatus.current === null ? <SortIcon/> : sortStatus.current === VisibleType.visible ? <SortUpIcon/> : <SortDownIcon/>}
                    </Typography>
                    <Typography id="title">제목</Typography>
                    <Typography id="language">언어</Typography>
                    <Typography id="reg-user">등록자</Typography>
                    <Typography id="edit-date" onClick={() => handleSort("uptDt")}>
                        수정일 {sortUptDt.current === null ? <SortIcon/> : sortUptDt.current === SortType.sortByAsc ? <SortDownIcon/> : <SortUpIcon/>}
                    </Typography>
                    <Typography id="reg-date" onClick={() => handleSort("regDt")}>
                        등록일 {sortRegDt.current === null ? <SortIcon/> : sortRegDt.current === SortType.sortByAsc ? <SortDownIcon/> : <SortUpIcon/>}
                    </Typography>
                </Box>
                <Box display='grid' rowGap='10px'>
                    {postList.length > 0 ?
                        postList.map((post) => (
                            <Box key={post.seq} display='flex' alignItems='center'
                                 className={classes.listItem} onClick={() => openPage("detail", post)}>
                                <Box id="status">
                                    {VisibleType.getName(post.visible) === "게시" &&
                                        <Box display='flex' alignItems='center' justifyContent='center'><CheckMarkIcon/>게시</Box>
                                    }
                                </Box>
                                <Typography id="title">{post.boardList[0].title}</Typography>
                                <Box id="language"><LanguageTags boardList={post.boardList}/></Box>
                                <Typography id="reg-user">{post.regUserKey}</Typography>
                                <Typography id="edit-date">{dataFormat(post.uptDt, 'datetime')}</Typography>
                                <Typography id="reg-date">{dataFormat(post.regDt, 'datetime')}</Typography>
                            </Box>
                        ))
                        :
                        <Box key={'none'} display='flex' alignItems='center' justifyContent='center' className={classes.listItemEmpty}>
                            <Typography id="title">조회할 게시물이 없습니다</Typography>
                        </Box>
                    }
                </Box>

                <Box display='flex' justifyContent='flex-end' className={classes.pageBottom}>
                    <Box  display='flex'>
                        <Typography>Total:&nbsp;</Typography>
                        <Typography>{totalPosts}</Typography>
                        <Typography>&nbsp;posts</Typography>
                    </Box>
                    <Pagination totalPages={totalPages}
                                currentPage={currentPage.current + 1}
                                onChange={onChangePage}/>
                </Box>
            </Box>
        </React.Fragment>
    )
}